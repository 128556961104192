import TableCSVExporter from "./ConverterCSV";
import React, { Component, Fragment } from "react";
import AUX from "../../../hoc/Aux_";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import axios from "../../Shared/auth-header";
import $ from "jquery";
import { toast } from "react-toastify";
import AuthService from "../../Auth/AuthService";
import Services from "./EditUser/EditUserService";
import AreasService from "../../Directory/Areas/AreasService";
import PermissionFeature from "../../Permissions/PermissionFeature";
import { store } from "../../../../src/index";
import { reset } from "redux-form";
import { connect } from "react-redux";
import AdvanceFilterModal from "../../Shared/AdvanceFilterModal";
import _ from "lodash";
import actionFilter from "../../../store/advancedfilter/action";
import Tags from "../../AdvanceSearch/AdvanceFilterTabs/Tags";
import savedSearchAction from "../../../store/saveSearch/action";
import actions from "../../../store/facility/action";
import AdvancedSavedSearch from "../../AdvanceSearch/SavedSearches/savedSearch.component";
import SavedSearchCustomizer from "../../AdvanceSearch/SavedSearches/SavedSearchCustomizer";
import moment from "moment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import FacilityPAC from "../../Dashboard/FacilityDashboard/FacilityPAC";
import CompositionModal from "../../AdvanceSearch/SavedSearches/CompositionModal";
import MemberBucket from "../../AdvanceSearch/SavedSearches/MemberBucket";
import * as Permissions from "../../Permissions/PermissionValues";
import * as SavedSearchTypes from "../../AdvanceSearch/SavedSearches/SavedSearchTypes";
import * as Constants from "../../AdvanceSearch/SavedSearches/Constants";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

//import { saveAs } from "file-saver";
import "jspdf-autotable";
import MailGunEmailTypes from "../../Email/Enums/MailGunEmailTypes";
import mailGunEmailActions from "../../../store/mailgunEmails/action";
import { CompositionModalMode } from "../../Registrations/Helpers/LookUpValues";
import Region from "../../AdvanceSearch/MemberDetails/Region";

const OWNERSHIP_ITEM = 1;
const BUCKET_ITEM = 2;
const SHARED_ITEM = 3;
class Users extends Component {
  constructor(props) {
    super(props);
    //console.log(this.props.match.params);
    this.state = {
      membersMethods: this.props.membersMethods,
      isLookup: this.props.isLookup || false,
      page: 1,
      facility: this.props.facility ? this.props.facility : "",
      region: this.props.region ? this.props.region : "",
      data: [],
      sizePerPage: this.props.filterState.currentFilterResultPageSize,
      totalCount: 0,
      inactiveTotalCount: 0,
      activeTotalCount: this.props.filterState.currentFilterResultTotalCount,
      keyword: "",
      SortOrder: "firstnameasc",
      isAdvanceSearch: false,
      delId: 0,
      filters: [],
      showActive: true,
      pacSumDonation: 0,
      pacCoutNonZero: 0,
      downloadData: [],
      downloadDataActive: [],
      downloadDataInActive: [],
      searchCriteria: "",
      check: "",
      showModal: false,
      showBucketModal: false,
      emails: [],
      displayMembers: [],
      memberId: AuthService.getProfile().memberData.id,
      BCounter: 0,
      keyword: "",
      pacMemberSearchTxt: "",
      pacMemberSearchTxt_err: "",
      valid: false,
      lastPlace: "",
      searchTitle: "search",
      selectedFields: [],
      columnsToView: [],
      // PAC Work
      advanced_search_title: "",
      ApiHit: "",
      ApiCriteria: "",
      ApiUrl: "",
      MemberTotalCount: 0,
      customizeColumns: [],
      selectedRows: [],
      local: false,
      savedSearchDetail: null,
      accoridanLoad: false,
      memberGridLoad: null,
      sharedSaveSearchTypeId: 0,
      sharedBucketSearchId: 0,
      disableIcon: "",
      waitingMessage:
        "Processing the file download request. File will automatically download momentarily.",
      activeTabKey: "",
      areasCount: 0,
      areasList: {},
    };
    this.ViewAllLink = this.ViewAllLink.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.doPreventDefault = this.doPreventDefault.bind(this);
    this.createAreaTeam = this.createAreaTeam.bind(this);
    this.getAreas = this.getAreas.bind(this);
    this.resetALLFilters = this.resetALLFilters.bind(this);
    this.onReturnNo = this.onReturnNo.bind(this);
    this.onResetTable = this.onResetTable.bind(this);
    this.onSelectAll = this.onSelectAll.bind(this);
    this.loadSavedBucketMember = this.loadSavedBucketMember.bind(this);
    this.setSavedBucketToBucket = this.setSavedBucketToBucket.bind(this);
    this.rowMove = this.rowMove.bind(this);
    this.TieBreakerGroupArrows = this.TieBreakerGroupArrows.bind(this);
    this.LoadPacColumn = this.LoadPacColumn.bind(this);
    this.propMethods = () => {
      this.updateTable = ({ page, data, totalCount, keyword }) => {
        this.setState({
          data,
          totalCount,
          page,
          keyword,
        });
      };

      this.handleMS = (name, selectedOption) => {
        let obj = this.state.advSearch;
        obj[name] = selectedOption.map((el) => el.value).join(",");
        obj[`${name}Selected`] = selectedOption;
        this.setState(obj);
        this.updateAdvanceSearch();
      };

      this.updateColumns = (fields) => {
        let newCols = fields
          .map((f) => {
            if (f.type.name == "address") {
              return {
                text: f.type.label,
                dataField: "addresses",
                sort: true,
                formatter: (cellContent, row) => {
                  return (
                    <span>
                      {row.street} {row.city} {row.state} {row.zipCode}
                    </span>
                  );
                },
              };
            } else {
              return {
                text: f.type.label,
                dataField: f.type.name,
                sort: true,
              };
            }
          })
          .filter((f) => {
            if (
              f.dataField == "names" ||
              f.dataField == "Facility" ||
              f.dataField == "Status" ||
              f.dataField == "Region" ||
              f.dataField == "Unit"
            ) {
              return false;
            } else {
              return true;
            }
          });
        this.setDefaultColumns();
        // let cols = Array.from(this.columnsDef);
        // Array.prototype.push.apply(cols, newCols);
        // cols.push(this.actionCol);
        // this.columns = cols;
      };

      this.updateFilters = (filters) => {
        this.setState({ filters });
        this.updateAdvanceSearch();
      };
      return this;
    };

    this.updateAdvanceSearch = () => {
      if (!this.state.isAdvanceSearch) this.setState({ isAdvanceSearch: true });
    };

    this.updateAdvSearch = ({ advSearch }) => {
      this.setState({ advSearch });
    };

    this.rowEvents = {
      onClick: (e, row, rowIndex) => {
        console.log(e);
        if (
          e.target.className.indexOf("telephone") >= 0 ||
          e.target.className.indexOf("email-to") >= 0 ||
          e.target.className.indexOf("view-map") >= 0
        ) {
          return;
        }
        if (this.state.isLookup) {
          this.state.membersMethods().setMember(row);
        } else if (e.target.className.indexOf("del") < 0) {
          if (true)
            if (this.props.facility) {
              this.props.his.push({
                pathname: "/directory/userAction/" + row.id,
                type: "edit",
                uid: row.id,

                keyword: $("#keyword").val(),
                showActive: this.state.showActive,
                page: this.state.page,
                backRef: {
                  uid: this.props.his.location.uid,
                  backRef: {
                    uid: this.props.his.location.backRef
                      ? this.props.his.location.backRef.uid
                      : undefined,
                  },
                },
              });
            } else {
              // console.log("row click member");
              if (!_.isEmpty($("#keyword").val())) {
                this.props.setBasicSearchKeyWord($("#keyword").val());
              } else {
                $("#keyword").val(this.props.filterState.basicSearchKeyWord);
                //  console.log("keyword is undefined");
              }
              //To Retain the Sort Order in case a user navigate back from Profile to Filters table Again
              localStorage.setItem("SortType", this.state.SortOrder);
              //Before viewing the Edit Member Page
              localStorage.setItem("isMemberEditRowClick", true);
              this.props.history.push({
                pathname: "/directory/userAction/" + row.id,
                type: "edit",
                uid: row.id,

                keyword: $("#keyword").val(),
                showActive: this.state.showActive,
              });
            }
        }
      },
    };
    this.rowClasses = (row, rowIndex) => {
      let { upArrow, downArrow } = this.TieBreakerGroupArrows(row, rowIndex);
      if (upArrow || downArrow) {
        return "bg-light-grey";
      }
    };
    this.pacColumn = {
      text: "PAC Donation",
      dataField: "pacDonation",
      hidden: !AuthService.canView(PermissionFeature.FinancialPacDonation),
      sort: true,
      formatter: (cell, row) => {
        let hasPermission = AuthService.canSPView(
          PermissionFeature.FinancialPacDonation,
          row.regionId,
          row.facilityId,
          row.id
        );
        if (!hasPermission) return "";
        const formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2,
        });
        return moment(cell).isValid() ? formatter.format(cell) : " ";
      },
    };
    this.orderColumn = {
      text: "",
      dataField: "",
      hidden: !AuthService.canView(PermissionFeature.FacilityMemberTieBreaker),
      sort: false,
      formatter: (cell, row, rowIndex) => {
        let { upArrow, downArrow } = this.TieBreakerGroupArrows(row, rowIndex);

        return (
          <div className="text-secondary">
            {upArrow ? (
              <i
                className="d-inline fas fa-lg fa-caret-up"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  this.rowMove(row, rowIndex, "Up");
                }}
              ></i>
            ) : (
              <i className="d-inline invisible fas fa-lg fa-caret-up"></i>
            )}
            {downArrow ? (
              <i
                class="d-inline fas fa-lg fa-caret-down"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  this.rowMove(row, rowIndex, "Down");
                }}
              ></i>
            ) : (
              <i class="d-inline invisible fas fa-lg fa-caret-down"></i>
            )}
          </div>
        );
      },
    };
    this.columns = [
      this.orderColumn,
      {
        text: "First Name",
        dataField: "firstName",
        sort: true,
        hidden: !AuthService.canView(PermissionFeature.PersonalInfoFirstName),
        formatter: (cell, row) => {
          if (
            AuthService.canSPView(
              PermissionFeature.PersonalInfoFirstName,
              row.regionId,
              row.facilityId
            )
          )
            return cell;
          else return "";
        },
      },
      {
        text: "Last Name",
        dataField: "lastName",
        sort: true,
        hidden: !AuthService.canView(PermissionFeature.PersonalInfoLastName),
        formatter: (cell, row) => {
          if (
            AuthService.canSPView(
              PermissionFeature.PersonalInfoFirstName,
              row.regionId,
              row.facilityId
            )
          )
            return cell;
          else return "";
        },
      },
      {
        text: "Region",
        dataField: "region",
        sort: true,
        hidden: !AuthService.canView(PermissionFeature.Region),
        formatter: (cell, row) => {
          if (AuthService.canSPView(PermissionFeature.Region, row.regionId))
            return cell;
          else return "";
        },
      },
      {
        text: "PAC",
        dataField: "pacdonation",
        sort: true,
        hidden: !AuthService.canView(PermissionFeature.FinancialPacDonation),
        formatter: (cell, row) => {
          if (AuthService.canView(PermissionFeature.FinancialPacDonation)) {
            if (!!cell) {
              return `$${cell.toLocaleString(navigator.language, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`;
            } else {
              return "";
            }
          } else return "";
        },
      },
      {
        text: "Facility",
        dataField: "facility",
        formatExtraData: JSON.stringify(this.state.data),
        sort: true,
        hidden: !AuthService.canView(PermissionFeature.Facility),
        formatter: (cell, row) => {
          if (
            AuthService.canSPView(
              PermissionFeature.Facility,
              undefined,
              row.facilityId
            )
          )
            return cell;
          else return "";
        },
      },
      //{
      //    text: "Member Number",
      //    dataField: "memberNumber",
      //    sort: true,
      //    hidden: !AuthService.canView(PermissionFeature.PersonalInfoMemberNumber),
      //    formatter: (cell, row) => {
      //        if (AuthService.canView(PermissionFeature.PersonalInfoMemberNumber)) return cell;
      //        else return "";
      //    },
      //},
      {
        text: "Member Type",
        dataField: "memberType",
        sort: true,
        hidden: !AuthService.canView(PermissionFeature.MembershipMemberType),
        formatter: (cell, row) => {
          if (AuthService.canView(PermissionFeature.MembershipMemberType))
            return cell;
          else return "";
        },
      },
      {
        text: "Primary Email",
        dataField: "emailAddress",
        sort: true,
        hidden: !AuthService.canView(PermissionFeature.ContactEmailAddresses),
        formatter: (cell, row) => {
          if (AuthService.canView(PermissionFeature.ContactEmailAddresses)) {
            return (
              <a
                target="_blank"
                href={"mailto:" + cell}
                onClick={this.doPreventDefault}
              >
                {cell}
              </a>
            );
          } else return "";
        },
      },
      {
        text: "Primary Phone",
        dataField: "phoneNumber",
        sort: false,
        hidden: !AuthService.canView(PermissionFeature.ContactPhones),
        formatter: (cell, row) => {
          if (AuthService.canView(PermissionFeature.ContactPhones)) {
            return (
              <a
                target="_blank"
                href={"tel:" + cell}
                onClick={this.doPreventDefault}
              >
                {cell}
              </a>
            );
          } else return "";
        },
      },
    ];

    this.actionCol = {
      isDummyField: true,
      text: "",
      headerAttrs: {
        width: 80,
      },
      headerClasses: "actionCol",
      formatter: (cellContent, row) => {
        return (
          <div className="actionCol">
            {AuthService.canUpdate(PermissionFeature.Member) && (
              <Link
                className="btn btn-sm btn-secondary waves-effect waves-light"
                to={{
                  pathname: "/directory/userAction",
                  type: "edit",
                  uid: row.id,
                }}
                color="secondary"
              >
                <i className="ion ion-md-arrow-round-forward" />
              </Link>
            )}
            {AuthService.canDelete(PermissionFeature.Member) && (
              <Link
                className="ml-2 btn btn-sm btn-danger waves-effect waves-light del"
                onClick={() => {
                  this.setState({ delId: row.id });
                }}
                to="#"
                data-target=".delete-modal"
                data-toggle="modal"
                size="sm"
              >
                <i className="fas fa-trash-alt del" />
              </Link>
            )}
          </div>
        );
      },
    };

    if (this.state.isLookup) {
      this.columns.pop();
    }
    this.RemotePagination = (
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
      showTotal,
      rowEvents,
      selectedFields,
      selectRowProp
    ) => {
      const { columnsToView } = this.state;
      const customTotal = (from, to, size) => (
        <span
          className="react-bootstrap-table-pagination-total"
          style={{ float: "left", marginTop: "-1px", marginRight: "4px" }}
        >
          {from}
          {" - "}
          {to} of {size} {"   "}
          <b style={{ marginLeft: "4px" }}> Show: </b>
        </span>
      );
      const isBasicSearched =
        !this.state.savedSearchId &&
        this.props.filterState.isAdvancedFilterSelected === false &&
        !this.props.facility &&
        this.props.filterState.basicSearchKeyWord &&
        this.props.filterState.basicSearchKeyWord !== "";
      const sizePerPageList = [
        {
          text: "10",
          value: 10,
        },
        {
          text: "25",
          value: 25,
        },
        {
          text: "30",
          value: 30,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "All",
          value:
            this.props.filterState.resultsAccordianType === 1
              ? totalSize
              : this.state.inactiveTotalCount,
        },
      ];
      const defaultPaginationConfigs = paginationFactory({
        paginationTotalRenderer: customTotal,
        page,
        sizePerPage,
        totalSize,
        showTotal,
        sizePerPageList,
      });
      return (
        <div>
          <div>
            {isBasicSearched && (
              <div className="collapse-user-box">
                <a
                  className="gird-collapse"
                  onClick={() => this.selectAccordian(1)}
                >
                  <h4>Active Employees </h4>{" "}
                  <p className="result">
                    ({this.state.activeTotalCount} results)
                  </p>
                  <p className="icon-result">
                    <i
                      className={
                        this.props.filterState.resultsAccordianType === 1
                          ? "fas fa-level-up-alt"
                          : "fas fa-level-down-alt"
                      }
                    ></i>
                  </p>
                </a>
              </div>
            )}

            {isBasicSearched === true ? (
              this.props.filterState.resultsAccordianType === 1 && (
                <div className={"mems-accoridan basic1"} id="accoridanType-1">
                  {this.state.accoridanLoad !== true ? (
                    <BootstrapTable
                      hover
                      remote
                      keyField="id"
                      selectRow={selectRowProp}
                      data={data}
                      columns={columnsToView}
                      defaultSortDirection="asc"
                      pagination={defaultPaginationConfigs}
                      onTableChange={onTableChange}
                      rowEvents={rowEvents}
                      id="membersListing"
                    />
                  ) : (
                    <div className="text-center pb-4 pt-2">
                      <div role="status" class="spinner-border">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  )}
                </div>
              )
            ) : (
              //Table Advnaced Search+Facility Dashboard+ Saved Searches
              <div className="NonBasicSearchMembers">
                {!_.isEmpty(columnsToView) ? (
                  <BootstrapTable
                    hover
                    remote
                    keyField="id"
                    selectRow={selectRowProp}
                    data={data}
                    columns={columnsToView}
                    defaultSortDirection="asc"
                    pagination={defaultPaginationConfigs}
                    onTableChange={onTableChange}
                    rowEvents={rowEvents}
                    rowClasses={this.props.facility ? this.rowClasses : false}
                    id="membersListing"
                  />
                ) : (
                  "No Visible Columns Found!"
                )}
              </div>
            )}
          </div>
          {isBasicSearched && (
            <div>
              <div className="collapse-user-box">
                <a
                  className="gird-collapse"
                  onClick={() => this.selectAccordian(2)}
                >
                  <h4>Inactive Employees </h4>{" "}
                  <p className="result">
                    ({this.state.inactiveTotalCount} results)
                  </p>
                  <p className="icon-result">
                    <i
                      className={
                        this.props.filterState.resultsAccordianType === 2
                          ? "fas fa-level-up-alt"
                          : "fas fa-level-down-alt"
                      }
                    ></i>
                  </p>
                </a>
              </div>
              {this.props.filterState.resultsAccordianType === 2 && (
                <div className="mems-accoridan" id="accoridanType-2">
                  {this.state.accoridanLoad !== true ? (
                    !_.isEmpty(this.state.data) ? (
                      <BootstrapTable
                        hover
                        remote
                        keyField="id"
                        selectRow={selectRowProp}
                        data={data}
                        columns={columnsToView}
                        defaultSortDirection="asc"
                        pagination={paginationFactory({
                          paginationTotalRenderer: customTotal,
                          page,
                          sizePerPage,
                          totalSize: this.state.inactiveTotalCount,
                          showTotal,
                          sizePerPageList,
                        })}
                        onTableChange={onTableChange}
                        rowEvents={rowEvents}
                        id="membersListingIsActive"
                      />
                    ) : (
                      <h2 className="notFound-mems">Members not found.</h2>
                    )
                  ) : (
                    <div className="text-center pb-4 pt-2">
                      <div role="status" class="spinner-border">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      );
    };
  }

  setActiveTab = (k) => {
    this.state.activeTabKey = k;
    this.setState({ activeTabKey: this.state.activeTabKey });
    this.getMembers("", this.props.facility);
  };
  TieBreakerGroupArrows(row, rowIndex) {
    let upArrow = false;
    let downArrow = false;
    if (!_.isEmpty(this.state.data) && this.state.data.length > 0) {
      // for up Arrow
      if (
        rowIndex != 0 &&
        this.state.data[rowIndex].bargainingUnitDate ==
        this.state.data[rowIndex - 1].bargainingUnitDate &&
        this.state.data[rowIndex].natcaBargainingUnitDate ==
        this.state.data[rowIndex - 1].natcaBargainingUnitDate &&
        this.state.data[rowIndex].entryOnDutyDate ==
        this.state.data[rowIndex - 1].entryOnDutyDate &&
        this.state.data[rowIndex].serviceComputationDate ==
        this.state.data[rowIndex - 1].serviceComputationDate &&
        (this.state.data[rowIndex].bargainingUnitDate != null ||
          this.state.data[rowIndex].natcaBargainingUnitDate != null ||
          this.state.data[rowIndex].entryOnDutyDate != null ||
          this.state.data[rowIndex].serviceComputationDate != null)
      ) {
        upArrow = true;
      }
      // for down Arrow
      if (
        this.state.data.length - 1 != rowIndex &&
        this.state.data[rowIndex].bargainingUnitDate ==
        this.state.data[rowIndex + 1].bargainingUnitDate &&
        this.state.data[rowIndex].natcaBargainingUnitDate ==
        this.state.data[rowIndex + 1].natcaBargainingUnitDate &&
        this.state.data[rowIndex].entryOnDutyDate ==
        this.state.data[rowIndex + 1].entryOnDutyDate &&
        this.state.data[rowIndex].serviceComputationDate ==
        this.state.data[rowIndex + 1].serviceComputationDate &&
        (this.state.data[rowIndex].bargainingUnitDate != null ||
          this.state.data[rowIndex].natcaBargainingUnitDate != null ||
          this.state.data[rowIndex].entryOnDutyDate != null ||
          this.state.data[rowIndex].serviceComputationDate != null)
      ) {
        downArrow = true;
      }
    }
    return { upArrow, downArrow };
  }
  rowMove = async (row, rowIndex, action) => {
    let tempRow = _.cloneDeep(row);
    let { data } = this.state;
    if (action == "Up" && rowIndex > 0 && data.length > 0) {
      await Services.TiebreakerUpdate(
        row.id,
        row.tieBreaker,
        data[rowIndex - 1].id,
        data[rowIndex - 1].tieBreaker
      );
      if (data[rowIndex - 1].tieBreaker == row.tieBreaker) {
        toast.warn(
          "Both the Tiebreakers' values are equal. After re-sorting, the members' positions will remain the same."
        );
      }
      row.tieBreaker = data[rowIndex - 1].tieBreaker;
      data[rowIndex - 1].tieBreaker = tempRow.tieBreaker;
      data[rowIndex] = data[rowIndex - 1];
      data[rowIndex - 1] = row;
    } else if (
      action == "Down" &&
      rowIndex < data.length - 1 &&
      data.length > 0
    ) {
      await Services.TiebreakerUpdate(
        row.id,
        row.tieBreaker,
        data[rowIndex + 1].id,
        data[rowIndex + 1].tieBreaker
      );
      if (data[rowIndex + 1].tieBreaker == row.tieBreaker) {
        toast.warn(
          "Both the Tiebreakers' values are equal. After re-sorting, the members' positions will remain the same."
        );
      }
      row.tieBreaker = data[rowIndex + 1].tieBreaker;
      data[rowIndex + 1].tieBreaker = tempRow.tieBreaker;
      data[rowIndex] = data[rowIndex + 1];
      data[rowIndex + 1] = row;
    }
    this.setState({ data: data });
  };

  selectAccordian(accordianType) {
    if (this.props.filterState.resultsAccordianType !== accordianType) {
      this.props.setMemberAccordianType(accordianType);
      this.setState({
        accoridanLoad: true,
        sizePerPage: 25,
        page: 1,
      });
      $(".mems-accoridan").slideUp("fast");
      $("#accoridanType-" + accordianType).slideDown();

      const url = `/api/member/SearchMembers`;
      this.props.setFilterResultPageNo(1);
      this.props.setFilterResultPageSize(25);
      const formData = {
        Keyword: this.props.filterState.basicSearchKeyWord,
        Facility: "",
        AreaId:
          this.state.areasCount > 0 ? parseInt(this.state.activeTabKey) : -1,
        Region: "",
        PageNo: 1,
        PageSize: 25,
        ShowOnlyActive: accordianType === 1 ? true : false,
        IsPACSearch: this.props.isPAC,
        IsFacilityCall: this.props.FacilityDashboard,
        IsRegionCall: this.props.isRegionCall,
        ShowBoth: false,
      };
      this.setState({
        ApiCriteria: formData,
        ApiHit: Constants.OTHERS,
        ApiUrl: url,
      });
      axios.post(url, formData).then(({ data }) => {
        if (data.status && data.status.toLowerCase() == "success") {
          this.setState({
            data: data.data,
          });
        } else {
          this.setState({
            data: [],
          });
        }
        this.setState({ accoridanLoad: false });
      });
    }
  }
  // PAC related Work
  searchTextKeyupHandler = (event) => {
    if (event.keyCode == 13) {
      this.handlePACSearch(event);
    }
  };

  // setSavedSearchId = (id) => {
  //   this.setState({ savedSearchId: id });
  // };

  onSelectAll = (isSelect, rows) => {
    let newRows = isSelect ? rows.map((row) => row.id) : [];
    this.setState({ selectedRows: newRows });

    if (isSelect) {
      if (localStorage.getItem("BucketSearch") === null) {
        localStorage.setItem("BucketSearch", JSON.stringify(rows));
        this.setState((prevState) => {
          return { BCounter: prevState.BCounter + rows.length };
        });
      } else {
        var stored = JSON.parse(localStorage.getItem("BucketSearch"));
        for (let i = 0; i < rows.length; i++) {
          stored.push(rows[i]);
        }
        localStorage.setItem("BucketSearch", JSON.stringify(stored));
        this.setState((prevState) => {
          return { BCounter: prevState.BCounter + rows.length };
        });
      }
    } else if (isSelect === false) {
      var items = JSON.parse(localStorage.getItem("BucketSearch"));
      var filteredItems = items.filter((x) => !rows.some((d) => d.id === x.id));
      localStorage.removeItem("BucketSearch");
      // if (filteredItems.length > 0) {
      localStorage.setItem("BucketSearch", JSON.stringify(filteredItems));
      // }

      this.setState((prevState) => {
        return { BCounter: prevState.BCounter - rows.length };
      });
      console.log(this.state.BCounter);
      if (this.state.BCounter === 1) {
        localStorage.removeItem("BucketSearch");
      }
    }
  };

  onSelectSingle = (row, isSelect, rowIndex, e) => {
    //if (rowIndex === 0) {
    var rowsToAdd = [];
    let modifiedRow;
    if (isSelect) {
      modifiedRow = [...this.state.selectedRows, row.id];
      if (localStorage.getItem("BucketSearch") === null) {
        rowsToAdd.push(row);
        localStorage.setItem("BucketSearch", JSON.stringify(rowsToAdd));
        this.setState((prevState) => {
          return { BCounter: prevState.BCounter + 1 };
        });
      } else {
        var stored = JSON.parse(localStorage.getItem("BucketSearch"));
        stored.push(row);
        localStorage.setItem("BucketSearch", JSON.stringify(stored));
        this.setState((prevState) => {
          return { BCounter: prevState.BCounter + 1 };
        });
      }
    } else {
      //modifiedRow = this.state.selectedRows.filter(x => x.id != row.id);
      modifiedRow = this.state.selectedRows;
      var index = modifiedRow.indexOf(row.id);
      if (index !== -1) {
        modifiedRow.splice(index, 1);
      }

      var items = JSON.parse(localStorage.getItem("BucketSearch"));
      var filteredItems = items.filter((x) => x.id != row.id);
      localStorage.removeItem("BucketSearch");
      localStorage.setItem("BucketSearch", JSON.stringify(filteredItems));
      this.setState((prevState) => {
        return { BCounter: prevState.BCounter - 1 };
      });
      if (this.state.BCounter === 1) {
        localStorage.removeItem("BucketSearch");
      }
    }
    this.setState({ selectedRows: modifiedRow });
    //}
  };

  onClearClick = () => {
    this.setState((state) => ({ selected: [] }));
  };

  searchTextChangeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });

    var inputValue = event.target.value.trim();
    if (inputValue !== "") {
      if (
        inputValue.indexOf(" ") > 0 &&
        !(
          /^[a-zA-Z]*$/.test(inputValue.split(" ")[0]) &&
          /^[a-zA-Z]*$/.test(inputValue.split(" ")[1])
        )
      ) {
        /// Contains 2 words &  One of them contains character other than alphabets -- Invalid
        //$("#" + event.target.id).addClass("inputBorderRed");
        $(".pacMemberSrchkeyword").addClass("inputBorderRed");
        this.setState({
          [event.target.name + "_err"]: "Name should contain alphabets only.",
        });
        this.setState({ valid: false });
      } else if (
        !(/^[a-zA-Z ]*$/.test(inputValue) || /^[0-9]*$/.test(inputValue))
      ) {
        /// alphanumeric or special characers with one word
        $(".pacMemberSrchkeyword").addClass("inputBorderRed");
        this.setState({
          [event.target.name + "_err"]: "Alphanumeric is not allowed.",
        });
        this.setState({ valid: false });
      } else {
        $(".pacMemberSrchkeyword").removeClass("inputBorderRed");
        this.setState({ [event.target.name + "_err"]: "" });
        this.setState({ valid: true });
      }
    } else {
      /// searching with empty field
      // this.setState({
      //     [event.target.name + "_err"]: "No search criteria entered."
      // });
      this.setState({ valid: false });
      this.setState({ [event.target.name + "_err"]: "" });
      $(".pacMemberSrchkeyword").removeClass("inputBorderRed");
    }
  };

  handlePACSearch = (e) => {
    e.preventDefault();
    if (this.state.valid) {
      this.getPACMembers(this.state.pacMemberSearchTxt, this.props.facility);
    } else {
      if (this.state.pacMemberSearchTxt.trim() === "") {
        // this.setState({
        //   ["pacMemberSearchTxt_err"]: "No search criteria entered."
        // });
        // $(".pacMemberSrchkeyword").addClass("inputBorderRed");

        this.getPACMembers("", this.props.facility);
      }
      return false;
    }
  };
  // PAC related Work END

  showSpinner = () => {
    $(".loading").show();
  };

  hideSpinner = () => {
    $(".loading").hide();
  };

  // setSavedSearchDetail = (savedSearchId, searchType, searchTitle) => {
  //   this.setState({
  //     savedSearchId: savedSearchId,
  //     searchType: searchType,
  //     searchTitle: searchTitle,
  //   });
  // };
  loadSavedBucketMember(searchId, totalCount, body) {
    let url = `/api/SavedSearch/GetBucketSearchResults?bucketSearchId=${searchId}&sortName=firstnameasc&offset=0&pageSize=${totalCount}`;
    axios.post(url, body).then(({ data: response }) => {
      this.setSavedBucketToBucket(response.data, response.totalCount);
    });
  }
  // For setting the saved bucket member to bucket
  setSavedBucketToBucket(data, totalCount) {
    if (data) {
      this.setState({ BCounter: totalCount, displayMembers: data });
      localStorage.setItem("BucketSearch", JSON.stringify(data));
      this.SelectRowsOnload();
    }
  }

  SelectRowsOnload = () => {
    var items = JSON.parse(localStorage.getItem("BucketSearch"));
    if (items !== null) {
      if (items.length > 0) {
        this.setState({ selectedRows: items.map((x) => x.id) });
      }
    }
  };

  loadSavedSearchData = (
    searchCriteria,
    customizedColumns,
    searchId,
    searchTypeId,
    pageNo,
    sizePerPage,
    sharedSaveSearchTypeId,
    sharedBucketSearchId
  ) => {
    const body = searchCriteria;
    //console.log("loadSavedSearchData called!!");
    const { SortOrder } = this.state;
    let offset = (pageNo - 1) * sizePerPage;
    let url = `/api/Member/AdvancedFilter?pageOffset=${offset}&pageFetch=${sizePerPage}&sortName=${SortOrder}`;
    if (
      searchTypeId &&
      (searchTypeId === SavedSearchTypes.BUCKET_ITEM ||
        (searchTypeId === SavedSearchTypes.SHARED_ITEM &&
          sharedSaveSearchTypeId === SavedSearchTypes.BUCKET_ITEM)) &&
      searchId
    ) {
      if (searchTypeId === SavedSearchTypes.SHARED_ITEM) {
        searchId = sharedBucketSearchId;
      }
      url = `/api/SavedSearch/GetBucketSearchResults?bucketSearchId=${searchId}&sortName=${SortOrder}&offset=${offset}&pageSize=${sizePerPage}`;
      this.setState({ BCounter: 0 });
      localStorage.removeItem("BucketSearch");
    }
    //this.showSpinner();
    this.setState({ memberGridLoad: true });
    axios.post(url, body).then(({ data: response }) => {
      //this.onResetTable();
      this.setColumnsHeaders(customizedColumns);
      this.setState({
        data: response.data,
        totalCount: response.totalCount,
        savedSearchCriteria: searchCriteria,
        page: pageNo,
      });
      //this.props.setFilterResult(response);
      //For setting the saved bucket member to bucket
      if (
        searchTypeId &&
        (searchTypeId === SavedSearchTypes.BUCKET_ITEM ||
          (searchTypeId === SavedSearchTypes.SHARED_ITEM &&
            sharedSaveSearchTypeId === SavedSearchTypes.BUCKET_ITEM)) &&
        searchId
      ) {
        //this.setState({ searchCriteria: {} });
        //this.props.setSavedSearchCriteria(null);
        if (
          response.totalCount &&
          response.totalCount > this.state.sizePerPage
        ) {
          if (searchTypeId === SavedSearchTypes.SHARED_ITEM) {
            searchId = sharedBucketSearchId;
          }
          this.loadSavedBucketMember(searchId, response.totalCount, body);
        } else {
          this.setSavedBucketToBucket(response.data, response.data.length);
        }
      }
      this.setState({
        customizeColumns: customizedColumns,
      });

      const { data, page, sizePerPage, totalCount } = this.state;
      //loadSavedSearchData call
      this.RemotePagination(
        data,
        page,
        sizePerPage,
        this.handleTableChange,
        totalCount,
        true,
        this.rowEvents
      );

      this.setState({
        memberGridLoad: false,
      });
      //this.hideSpinner();
      $(".loading").hide();
    });
  };

  setSavedSearchCriteria = (
    savedSearchCriteriaDetail,
    searchTypeId,
    searchId,
    sharedSaveSearchTypeId,
    sharedBucketSearchId
  ) => {
    //console.log("setSavedSearchCriteria called");

    this.props.stopSavedSearchCriteria();
    if (savedSearchCriteriaDetail) {
      let criteria = savedSearchCriteriaDetail.searchCriteria;
      // if (
      //   searchTypeId &&
      //   (searchTypeId === SavedSearchTypes.BUCKET_ITEM ||
      //   (searchTypeId === SavedSearchTypes.SHARED_ITEM && sharedSaveSearchTypeId === SavedSearchTypes.BUCKET_ITEM)) &&
      //   searchId
      // ) {
      //   criteria = null;
      // }
      this.setState({
        searchCriteria: criteria,
        customizedColumns: savedSearchCriteriaDetail.searchColumns,
        savedSearchId: searchId,
        savedSearchTypeId: searchTypeId,
        sharedSaveSearchTypeId,
        sharedBucketSearchId,
      });

      this.onResetTable();
      this.setState({
        sizePerPage: 25,
      });
      this.props.setFilterResultPageNo(1);
      this.loadSavedSearchData(
        savedSearchCriteriaDetail.searchCriteria,
        savedSearchCriteriaDetail.searchColumns,
        searchId,
        searchTypeId,
        1,
        25,
        sharedSaveSearchTypeId,
        sharedBucketSearchId
      );
      if (
        searchTypeId &&
        (searchTypeId === SavedSearchTypes.BUCKET_ITEM ||
          (searchTypeId === SavedSearchTypes.SHARED_ITEM &&
            sharedSaveSearchTypeId === SavedSearchTypes.BUCKET_ITEM)) &&
        searchId
      ) {
        // this.props.setSavedSearchCriteria(null);
        this.resetALLFilters();
      } else {
        this.props.getNewFilteredData(
          savedSearchCriteriaDetail.searchCriteria,
          false
        );

        // this.props.setAdvanceFilterLoader(false);
        this.props.getMemberTotalCount(
          savedSearchCriteriaDetail.searchCriteria
        );

        this.props.setFilterData(savedSearchCriteriaDetail.searchCriteria);
        this.props.setTempFilterData(savedSearchCriteriaDetail.searchCriteria);
      }
      return;
    }
  };

  // setSavedSearchDataState = (
  //   savedSearchCriteria,
  //   filterData,
  //   columnHeaders
  // ) => {
  //   this.setState({ searchResultData: filterData });
  //   this.setState({ searchResultHeaders: columnHeaders });
  //   this.setState({ savedSearchCriteria: savedSearchCriteria });
  //   this.setState({ page: 1 });
  //   this.onResetTable();

  //   this.setColumnsHeaders(columnHeaders);
  //   this.setState({ data: filterData.data });
  //   this.setState({ totalCount: filterData.totalCount });
  //   const { data, sizePerPage, page, totalCount } = this.state;
  //   this.RemotePagination(
  //     data,
  //     0,
  //     sizePerPage,
  //     this.handleTableChange,
  //     totalCount,
  //     true,
  //     this.rowEvents
  //   );
  // };
  componentDidUpdate(prevProps, prevState) {
    if (this.props.SavedSearch.ResetSavedSearch) {
      this.resetSavedSearch();
    } else {
      let shouldProcessSavedSearch =
        !_.isEmpty(this.props.SavedSearch.savedSearchCriteria) &&
        this.props.filterState.loadingAdvanceFilter === false &&
        (JSON.stringify(this.props.SavedSearch.savedSearchCriteria) !=
          JSON.stringify(prevProps.SavedSearch.savedSearchCriteria) ||
          JSON.stringify(
            this.props.SavedSearch.savedSearchCriteria.savedSearchCriteriaDetail
              .searchCriteria
          ) != JSON.stringify(this.state.searchCriteria));
      if (
        !this.props.facility &&
        this.props.SavedSearch.isSavedSearchSelected == true &&
        this.props.filterState.isAdvancedFilterSelected === false
      ) {
        ////console.log("shouldProcessSavedSearch value", shouldProcessSavedSearch);

        if (shouldProcessSavedSearch) {
          //console.log("shouldProcessSavedSearch", shouldProcessSavedSearch);

          let {
            savedSearchCriteriaDetail,
            searchTypeId,
            searchId,
            sharedSaveSearchTypeId,
            sharedBucketSearchId,
          } = this.props.SavedSearch.savedSearchCriteria;

          this.setSavedSearchCriteria(
            savedSearchCriteriaDetail,
            searchTypeId,
            searchId,
            sharedSaveSearchTypeId,
            sharedBucketSearchId
          );
        } else {
          ///console.log("shouldProcessSavedSearch", shouldProcessSavedSearch);
        }
        if (
          JSON.stringify(this.props.SavedSearch.SavedSearchDetail) !=
          JSON.stringify(prevProps.SavedSearch.SavedSearchDetail) ||
          JSON.stringify(this.props.SavedSearch.SavedSearchDetail) !=
          JSON.stringify(this.state.savedSearchDetail)
        ) {
          this.setState({
            savedSearchDetail: this.props.SavedSearch.SavedSearchDetail,
          });
          //console.log("set state savedSearchDetail");
        }
      }

      if (
        !this.props.facility &&
        this.props.SavedSearch.isSavedSearchSelected === false &&
        this.props.filterState.isAdvancedFilterSelected === false &&
        this.props.SavedSearch.BucketSearchId > 0
      ) {
        if (
          !_.isEmpty(this.props.SavedSearch.savedSearchCriteria) &&
          this.props.filterState.loadingAdvanceFilter === false &&
          (JSON.stringify(this.props.SavedSearch.savedSearchCriteria) !=
            JSON.stringify(prevProps.SavedSearch.savedSearchCriteria) ||
            JSON.stringify(
              this.props.SavedSearch.savedSearchCriteria
                .savedSearchCriteriaDetail.searchCriteria
            ) != JSON.stringify(this.state.searchCriteria))
        ) {
          let {
            savedSearchCriteriaDetail,
            searchTypeId,
            searchId,
            sharedSaveSearchTypeId,
            sharedBucketSearchId,
          } = this.props.SavedSearch.savedSearchCriteria;

          // this.setSavedSearchCriteria(
          //   savedSearchCriteriaDetail,
          //   searchTypeId,
          //   searchId,
          //   sharedSaveSearchTypeId,
          //   sharedBucketSearchId
          // );
        }
        if (
          JSON.stringify(this.props.SavedSearch.SavedSearchDetail) !=
          JSON.stringify(prevProps.SavedSearch.SavedSearchDetail) ||
          JSON.stringify(this.props.SavedSearch.SavedSearchDetail) !=
          JSON.stringify(this.state.savedSearchDetail)
        ) {
          this.setState({
            savedSearchDetail: this.props.SavedSearch.SavedSearchDetail,
          });
        }
      }
    }
    //***********Work for FilterMembersTab Used in EditFilterModal for Fiters Listing***************//
    const { refreshMembersFilterUsersTable, filterMembersTab } = this.props;
    const {
      isAdvancedFilterOpenedInTab,
      allowFilterUpdateMembersTab,
    } = this.props.SavedSearch;
    const { filterResult } = this.props.filterState;
    const shouldUpdateTable =
      filterMembersTab &&
      refreshMembersFilterUsersTable &&
      isAdvancedFilterOpenedInTab &&
      refreshMembersFilterUsersTable &&
      allowFilterUpdateMembersTab &&
      !_.isEmpty(filterResult) &&
      !_.isEqual(prevState.data, filterResult.data);
    if (shouldUpdateTable) {
      this.onAdvancedChangeTable(filterResult);
      this.props.setAllowFilterUpdateMembersTab(false);
    }
  }

  resetSavedSearch() {
    this.onResetTable();
    this.props.isSavedSearchSelected(false);

    this.setState({
      data: [],
      searchCriteria: {},
      customizedColumns: [],
      savedSearchId: 0,
      savedSearchTypeId: 0,
      savedSearchDetail: null,
    });

    this.props.setSavedSearchCriteria(null);
    this.props.setSavedSearchDetail(null);

    if (
      !_.isEmpty(this.props.filterState.filterData) &&
      !_.isEmpty(this.props.filterState.filterData.searchApplied)
    ) {
      //this.props.setResetAdvanceFilter(false);
      this.resetALLFilters();
    }
    this.props.stopResetSavedSearch();
  }
  setColumnsHeaders = (colHeaders) => {
    if (colHeaders) {
      let searchCols = [];
      let dateColumns = [
        "dateOfBirth",
        "plannedRetirementDate",
        "entryOnDutyDate",
        "serviceComputationDate",
        "bargainingUnitDate",
        "natcaBargainingUnitDate",
        "unionJoinedDate",
        "mandatoryRetirementDate",
        "retirementDate",
      ];
      let boolColumns = [
        "hasLifeInsurance",
        "hasLongTermDisability",
        "isRetiredMilitaryController",
        "allowEmails",
        "allowPhoneCall",
        "allowPostalMail",
        "allowTextMessages",
        "allowFacebook",
        "isLocked",
        "isEnabled",
        "allowtext_legislative",
        "allowtext_newsalerts",
        "isActive",
      ];
      let emailColumns = ["emailAddress"];
      let phoneColumns = ["phoneNumber"];
      let addressColumns = ["mailAddress", "deliveryAddress", "homeAddress"];

      let currencyColumns = [
        "yearlySalary",
        "hourlySalary",
        "drfDonation",
        "pacDonation",
        "ncf",
        "expecteddues",
      ];

      this.setState({
        selectedFields: colHeaders,
      });
      colHeaders.map((col, index, array) => {
        let checkContactColumn = () => {
          if (
            emailColumns.indexOf(col.backend_column_name) != -1 ||
            phoneColumns.indexOf(col.backend_column_name) != -1 ||
            addressColumns.indexOf(col.backend_column_name) != -1
          ) {
            let hasPermission = AuthService.canView(
              PermissionFeature.MembersContact
            );
            if (!hasPermission) return false;
          }
          return true;
        };
        let colDef = {
          text: col.column_name,
          dataField: col.backend_column_name,
          sort: true,
          formatter: (cell, row) => {
            let hasPermission = AuthService.canView(col.permission_feature_id);
            if (!hasPermission) return "";

            hasPermission = AuthService.canSPView(
              col.permission_feature_id,
              row.regionId,
              row.facilityId,
              row.id
            );
            if (!hasPermission) return "";

            if (
              emailColumns.indexOf(col.backend_column_name) != -1 ||
              phoneColumns.indexOf(col.backend_column_name) != -1 ||
              addressColumns.indexOf(col.backend_column_name) != -1
            ) {
              hasPermission = AuthService.canSPView(
                PermissionFeature.MembersContact,
                row.regionId,
                row.facilityId,
                row.id
              );
              if (!hasPermission) return "";
            }

            if (dateColumns.indexOf(col.backend_column_name) != -1) {
              return moment(cell).isValid()
                ? moment(cell).format("MM/DD/YYYY")
                : " ";
            } else {
              if (boolColumns.indexOf(col.backend_column_name) != -1) {
                if (col.backend_column_name == "isActive") {
                  if (cell == null) {
                    return "No Status";
                  } else {
                    if (cell != "Active" && cell != "InActive") {
                      return cell === true ? "Active" : "In Active";
                    }
                    return cell;
                  }
                } else {
                  if (cell == null) {
                    return "No";
                  } else {
                    return cell === true ? "Yes" : "No";
                  }
                }
              } else {
                if (emailColumns.indexOf(col.backend_column_name) != -1) {
                  return cell == null ? (
                    ""
                  ) : cell.trim().length == 0 ? (
                    ""
                  ) : (
                    <a target="_blank" href={`mailto:${cell}`}>
                      <span className="email-to">{cell}</span>
                    </a>
                  );
                } else {
                  if (phoneColumns.indexOf(col.backend_column_name) != -1) {
                    return cell == null ? (
                      ""
                    ) : cell.trim().length == 0 ? (
                      ""
                    ) : (
                      <a target="_blank" href={`tel:${cell}`}>
                        <span className="telephone">{cell}</span>
                      </a>
                    );
                  } else {
                    if (addressColumns.indexOf(col.backend_column_name) != -1) {
                      if (!cell || cell == null || cell.trim().length <= 0) {
                        return "";
                      } else {
                        let addlat = cell.split("|");
                        let arr = addlat[0].split(",");
                        let lat = null,
                          long = null;
                        if (addlat[1] && addlat[1].trim().length > 0) {
                          let arr2 = addlat[1].split(",");
                          lat = arr2[0];
                          long = arr2[1];
                        }
                        let filtered = arr.filter((item) => {
                          return item != null && item.trim().length > 0;
                        });
                        console.log(filtered);
                        if (filtered && filtered.length > 0) {
                          let address = filtered.join(", ");
                          console.log(address);
                          let is_latlong =
                            lat &&
                            long &&
                            AuthService.canSPView(
                              PermissionFeature.AddressLatLong,
                              row.regionId,
                              row.facilityId,
                              row.id
                            );
                          return (
                            <>
                              <a
                                target="_blank"
                                className="view-map"
                                href={`http://maps.google.com/?q=${address}`}
                              >
                                <span className="view-map">{address}</span>
                              </a>
                              {is_latlong && (
                                <>
                                  {" "}
                                  <span className="card-text">
                                    <a
                                      target="_blank"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        e.nativeEvent.stopImmediatePropagation();
                                      }}
                                      href={
                                        "https://www.google.com/maps?q=" +
                                        lat +
                                        "," +
                                        long
                                      }
                                    >
                                      <span className="font-italic">
                                        {lat}/{long}
                                      </span>
                                    </a>
                                  </span>
                                </>
                              )}
                            </>
                          );
                        }
                      }
                    } else if (col.backend_column_name == "facebookid") {
                      if (
                        cell &&
                        cell != null &&
                        cell != null &&
                        cell != undefined
                      ) {
                        return (
                          <a
                            target="_blank"
                            href={"https://facebook.com/" + cell}
                          >
                            <span className="">{cell}</span>
                          </a>
                        );
                      }
                      return cell;
                    } else if (
                      currencyColumns.indexOf(col.backend_column_name) != -1
                    ) {
                      const formatter = new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 2,
                      });
                      return moment(cell).isValid()
                        ? formatter.format(cell)
                        : " ";
                    } else {
                      return cell;
                    }
                  }
                }
              }
            }
          },
        };
        if (
          AuthService.canView(col.permission_feature_id) &&
          checkContactColumn()
        ) {
          searchCols.push(colDef);
        }
      });
      if (this.props.facility) {
        this.columns = [this.orderColumn, ...searchCols];
      } else {
        this.columns = searchCols;
      }

      this.setState({
        columnsToView: this.columns,
      });
      // this.setState({
      //   selectedFields: this.columns,
      // });
    }
  };

  onReturnNo() {
    /// API
    if (!_.isEmpty(this.props.filterState.filterData.searchApplied)) {
      this.props.getMemberTotalCount(this.props.filterState.filterData);
    } else {
      this.props.setReturnResultNo(0);
    }
  }

  canSelect = () => {
    return false;
  };
  LoadPacColumn() {
    if (
      !_.isEmpty(this.state.columnsToView) &&
      this.state.columnsToView.findIndex((x) => x.dataField == "pacDonation") ==
      -1 &&
      this.props.isPAC &&
      AuthService.canView(PermissionFeature.FinancialPacDonation) &&
      !this.props.facilityDashboardState.IsMemberAccordianLoading
    ) {
      this.columns.push(this.pacColumn);
    }
  }
  doPreventDefault = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };
  handleTableChange = (
    type,
    { sortField, sortOrder, data, page, sizePerPage }
  ) => {
    //console.log("In handleTableChange");
    debugger;
    if (!this.props.filterState.localPaging) {
      let url = `/api/member/SearchMembers`;
      this.props.setFilterResultPageNo(page);
      this.props.setFilterResultPageSize(sizePerPage);
      this.setState({
        page: page,
        sizePerPage: sizePerPage,
      });
      if (type === "sort") {
        this.state.SortOrder = sortField + sortOrder;
        this.setState({ SortOrder: this.state.SortOrder });
        localStorage.setItem("SortType", sortField + sortOrder);
        if (localStorage.getItem("SortType") !== null) {
          this.setState({ SortOrder: localStorage.getItem("SortType") });
        }
      }
      let formData = {};
      if (this.props.facility || this.props.region) {
        formData = {
          Keyword: "",
          PageNo: page,
          Facility: this.state.facility,
          AreaId:
            this.state.areasCount > 0 ? parseInt(this.state.activeTabKey) : -1,
          Region: this.state.region,
          PageSize: sizePerPage,
          //SortOrder: this.state.SortOrder,
          ShowOnlyActive: this.state.showActive,
          IsFacilityCall: this.props.FacilityDashboard,
          IsRegionCall: this.props.isRegionCall,
          IsPACSearch: this.props.isPAC,
          SortBy: sortField,
          SortingOrder: sortOrder,
        };
      } else if (
        this.props.filterState.isAdvancedFilterSelected === false &&
        this.props.SavedSearch.isSavedSearchSelected === false &&
        !this.props.facility &&
        this.props.filterState.basicSearchKeyWord &&
        this.props.filterState.basicSearchKeyWord !== ""
      ) {
        formData = {
          Keyword: this.props.filterState.basicSearchKeyWord,
          PageNo: page,
          Facility: "",
          AreaId:
            this.state.areasCount > 0 ? parseInt(this.state.activeTabKey) : -1,
          Region: "",
          PageSize: sizePerPage,
          //SortOrder: this.state.SortOrder,
          ShowOnlyActive:
            this.props.filterState.resultsAccordianType === 1 ? true : false, //this.state.showActive,
          IsFacilityCall: this.props.FacilityDashboard,
          IsRegionCall: this.props.isRegionCall,
          SortBy: sortField,
          SortingOrder: sortOrder,
        };
      }
      $(".loading").show();
      if (
        !this.props.filterState.isAdvancedFilterSelected &&
        this.props.SavedSearch.isSavedSearchSelected === false
      ) {
        // this.setState({
        //   savedSearchId: 0,
        // });
        this.setDefaultColumns();
        if (_.isEmpty(formData)) {
          if (!_.isEmpty(this.state.savedSearchCriteria)) {
            if (sizePerPage <= 0) {
              sizePerPage = this.state.sizePerPage;
            }
            var offset = (page - 1) * sizePerPage;
            url =
              "/api/Member/AdvancedFilter?pageOffset=" +
              offset +
              "&pageFetch=" +
              sizePerPage +
              "&sortName=" +
              this.state.SortOrder;
            // formData = {
            //   Keyword: "",
            //   PageNo: page,
            //   Facility: "",
            //   PageSize: sizePerPage,
            //   SortOrder: this.state.sortOrder,
            //   ShowOnlyActive: this.state.showActive
            // };
            formData = this.state.savedSearchCriteria;
          }
        }
        axios.post(url, formData).then(({ data }) => {
          if (data.status && data.status.toLowerCase() == "success") {
            this.setState({
              data: data.data,
              totalCount: data.totalCount,
              page,
              sizePerPage,
            });
          } else {
            toast.error(data.message);
          }
          $(".loading").hide();
        });
      } else {
        if (
          this.state.savedSearchId &&
          this.state.savedSearchId > 0 &&
          this.props.SavedSearch.isSavedSearchSelected === true
        ) {
          let offset = (page - 1) * sizePerPage;
          this.loadSavedSearchData(
            this.state.searchCriteria,
            this.state.customizedColumns,
            this.state.savedSearchId,
            this.state.savedSearchTypeId,
            page,
            sizePerPage,
            this.state.sharedSaveSearchTypeId,
            this.state.sharedBucketSearchId
          );
        } else {
          if (!_.isEmpty(this.props.filterState.filterData)) {
            var offset = (page - 1) * sizePerPage;

            url =
              "/api/Member/AdvancedFilter?pageOffset=" +
              offset +
              "&pageFetch=" +
              sizePerPage +
              "&sortName=" +
              this.state.SortOrder;

            axios
              .post(url, this.props.filterState.filterData)
              .then(({ data }) => {
                if (data.status && data.status.toLowerCase() == "success") {
                  this.setState({
                    data: data.data,
                    totalCount: data.totalCount,
                    page,
                    sizePerPage,
                  });

                  this.props.setFilterResult(data);
                  this.props.setAdvancedSearch(true);
                  this.props.setIsBasicSearchSelected(false);
                } else {
                  console.log(data);
                  toast.error(data.message);
                }
                $(".loading").hide();
              });
          }
        }
      }
    } else {
      this.iterator(page, data, sizePerPage);
    }
  };

  getPACMembers(key, facility) {
    if (_.isEmpty(facility)) {
      $(".loading").show();
    } else {
      this.props && this.props.setFacilityMembersLoading(true);
    }
    console.log("getPACMembers 11128");
    const url = `/api/member/SearchMembers`;
    const formData = {
      Keyword: key,
      Facility: facility,
      Region: "",
      PageNo: 1,
      PageSize: this.state.sizePerPage,
      ShowOnlyActive: this.state.showActive,
      IsPACSearch: this.props.isPAC,
      IsFacilityCall: this.props.FacilityDashboard,
      IsRegionCall: this.props.isRegionCall,
      ShowBoth: false,
    };
    this.setState({
      ApiCriteria: formData,
      ApiHit: Constants.OTHERS,
      ApiUrl: url,
    });
    axios.post(url, formData).then(({ data }) => {
      if (data.status && data.status.toLowerCase() == "success") {
        this.setState({
          data: data.data,
          totalCount: data.totalCount,
          pacSumDonation: data.pacSum,
          pacCoutNonZero: data.pacCount,
        });
        if (!_.isEmpty(key)) {
          $("#keyword").val(key);
        }

        $(".loading").hide();
        this.props.setFacilityMembersLoading(false);
      } else {
        $(".loading").hide();
        this.props.setFacilityMembersLoading(false);
        toast.warn(data.message);
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    var routeChanged = nextProps.location !== this.props.location;
  }

  getMembers(key, facility, region) {
    if (_.isEmpty(facility)) {
      //    $(".loading").show();
      this.setState({ memberGridLoad: true });
    } else {
      this.props && this.props.setFacilityMembersLoading(true);
    }

    const url = `/api/member/SearchMembers`;
    let pageNo = this.props.filterState.currentFilterResultPage;

    // if (key && key.trim().length > 0) {
    //   pageNo = 1;
    // }

    const formData = {
      Keyword: key,
      Facility: facility,
      AreaId:
        this.state.areasCount > 0 ? parseInt(this.state.activeTabKey) : -1,
      Region: region,
      PageNo: pageNo,
      PageSize: this.state.sizePerPage,
      ShowOnlyActive:
        this.props.filterState.resultsAccordianType === 1 ? true : false,
      IsPACSearch: this.props.isPAC,
      IsFacilityCall: this.props.FacilityDashboard,
      IsRegionCall: this.props.isRegionCall,
      ShowBoth: false,
    };

    // if (this.props.filterState.isAdvancedFilterSelected) {
    //   this.props.resetSavedSearch();
    // }
    //   this.resetALLFilters();
    this.setState({
      ApiCriteria: formData,
      ApiHit: Constants.OTHERS,
      ApiUrl: url,
    });

    //this.props.setSavedSearchDetail(null);
    axios.post(url, formData).then(({ data }) => {
      if (data.status && data.status.toLowerCase() == "success") {
        this.setState({
          data: _.isEmpty(data.data) ? [] : data.data,
          totalCount:
            this.props.filterState.resultsAccordianType === 1
              ? data.totalCount
              : this.props.filterState.currentFilterResultTotalCount,
          inactiveTotalCount: data.inActiveCount,
          activeTotalCount:
            this.props.filterState.resultsAccordianType === 1
              ? data.totalCount
              : this.props.filterState.currentFilterResultTotalCount,
          pacSumDonation: data.pacSum,
          pacCoutNonZero: data.pacCount,
          memberGridLoad: false,
        });

        if (this.props.filterState.resultsAccordianType === 1) {
          this.props.setFilterResultTotalCount(data.totalCount);
        }
        if (!_.isEmpty(key)) {
          $("#keyword").val(key);
        }

        //  $(".loading").hide();
        this.props.setFacilityMembersLoading(false);
      } else {
        //$(".loading").hide();
        this.setState({ memberGridLoad: false });
        this.props.setFacilityMembersLoading(false);
        console.log("error in getMembers() api" + data.message);
      }
    });
  }
  async getAreas() {
    if (this.props.facility) {
      let result = await AreasService.GetAreasByFacilityCode(
        this.props.facility
      );
      if (result != null) {
        if (result.totalCount > 0) {
          await this.setState({
            activeTabKey: result.data[0].id,
            areasCount: result.totalCount,
            areasList: result.data,
          });
        }
      }
    }
  }
  async componentWillMount() {
    if (this.props.facility) {
      this.props.resetSavedSearch();
      await this.getAreas();
      this.getMembers("", this.props.facility, "");
      this.setState({ facility: this.props.facility });
    } else if (this.props.region) {
      debugger;
      this.props.resetSavedSearch();
      this.getMembers("", "", this.props.region);
      this.setState({ region: this.props.region });
    }
  }

  resetALLFilters() {
    if (!_.isEmpty(this.props.filterState.filterData)) {
      store.dispatch(reset("drfForm"));
      store.dispatch(reset("pacForm"));
      store.dispatch(reset("ageForm"));
      store.dispatch(reset("nameForm"));
      store.dispatch(reset("memberECIForm"));
      store.dispatch(reset("memberNoForm"));
      store.dispatch(reset("facilityRoutingSymbolForm"));
      store.dispatch(reset("orgCodeForm"));
      store.dispatch(reset("hourlySalaryForm"));
      store.dispatch(reset("annualSalaryForm"));
      store.dispatch(reset("emailForm"));
      store.dispatch(reset("plannedRetirementForm"));
      store.dispatch(reset("retirementForm"));
      store.dispatch(reset("sCDForm"));
      store.dispatch(reset("eODDateForm"));
      store.dispatch(reset("nATCABUDateForm"));
      store.dispatch(reset("bUDateForm"));
      store.dispatch(reset("unionStartDateForm"));
      store.dispatch(reset("unionJoinDateForm"));
      store.dispatch(reset("teamPositionForm"));
      store.dispatch(reset("activeDatePositionForm"));

      this.props.setAdvancedSearch(false);
      this.props.filterState.filterData.searchApplied = [];

      if (this.props.filterState.filterData.facility) {
        this.props.filterState.filterData.facility = this.props.filterState.filterData.facility.map(
          (item) => {
            item.checked = false;
            return item;
          }
        );
      } else {
        this.props.filterState.filterData.facility = [];
      }

      if (this.props.filterState.filterData.memberStatus) {
        this.props.filterState.filterData.memberStatus = this.props.filterState.filterData.memberStatus.map(
          (item) => {
            if (item.memberStatus === "Active") {
              item.checked = true;
            } else {
              item.checked = false;
            }
            return item;
          }
        );
      }

      if (this.props.filterState.filterData.memberType) {
        this.props.filterState.filterData.memberType = this.props.filterState.filterData.memberType.map(
          (item) => {
            item.checked = false;
            return item;
          }
        );
      }

      if (this.props.filterState.filterData.region) {
        this.props.filterState.filterData.region = this.props.filterState.filterData.region.map(
          (item) => {
            item.checked = false;
            return item;
          }
        );
      }

      if (this.props.filterState.filterData.shirtSize) {
        this.props.filterState.filterData.shirtSize = this.props.filterState.filterData.shirtSize.map(
          (item) => {
            item.checked = false;
            return item;
          }
        );
      }

      if (this.props.filterState.filterData.gender) {
        this.props.filterState.filterData.gender = this.props.filterState.filterData.gender.map(
          (item) => {
            item.checked = false;
            return item;
          }
        );
      }

      this.props.filterState.filterData.name = null;
      this.props.filterState.filterData.age = null;
      this.props.filterState.filterData.pac = null;
      this.props.filterState.filterData.drf = null;
      this.props.filterState.filterData.memberECI = null;
      this.props.filterState.filterData.memberNumber = null;

      this.props.filterState.filterData.dutyStation = this.props.filterState.filterData.dutyStation.map(
        (item) => {
          item.checked = false;
          return item;
        }
      );
      this.props.filterState.filterData.unit = this.props.filterState.filterData.unit.map(
        (item) => {
          item.checked = false;
          return item;
        }
      );
      this.props.filterState.filterData.occupationSeries = this.props.filterState.filterData.occupationSeries.map(
        (item) => {
          item.checked = false;
          return item;
        }
      );
      this.props.filterState.filterData.employer = this.props.filterState.filterData.employer.map(
        (item) => {
          item.checked = false;
          return item;
        }
      );
      this.props.filterState.filterData.careerLevel = this.props.filterState.filterData.careerLevel.map(
        (item) => {
          item.checked = false;
          return item;
        }
      );
      this.props.filterState.filterData.payGrade = this.props.filterState.filterData.payGrade.map(
        (item) => {
          item.checked = false;
          return item;
        }
      );
      this.props.filterState.filterData.flsaCategory = this.props.filterState.filterData.flsaCategory.map(
        (item) => {
          item.checked = false;
          return item;
        }
      );
      this.props.filterState.filterData.communication = this.props.filterState.filterData.communication.map(
        (item) => {
          item.checked = false;
          return item;
        }
      );
      this.props.filterState.filterData.address.addressTypes = this.props.filterState.filterData.address.addressTypes.map(
        (item) => {
          item.checked = false;
          return item;
        }
      );
      this.props.filterState.filterData.address.states = this.props.filterState.filterData.address.states.map(
        (item) => {
          item.checked = false;
          return item;
        }
      );
      this.props.filterState.filterData.address.city = "";
      this.props.filterState.filterData.address.country = "";
      this.props.filterState.filterData.address.zip = "";
      this.props.filterState.filterData.veteran = this.props.filterState.filterData.veteran.map(
        (item) => {
          item.checked = false;
          return item;
        }
      );

      this.props.filterState.filterData.retiredMilitary = this.props.filterState.filterData.retiredMilitary.map(
        (item) => {
          item.checked = false;
          return item;
        }
      );
      this.props.filterState.filterData.facilityRoutingSymbol = null;
      this.props.filterState.filterData.orgCode = null;
      this.props.filterState.filterData.hourlySalary = null;
      this.props.filterState.filterData.annualSalary = null;
      this.props.filterState.filterData.email = null;
      this.props.filterState.filterData.plannedRetirement = null;
      this.props.filterState.filterData.retirement = null;
      this.props.filterState.filterData.scd = null;
      this.props.filterState.filterData.eodDate = null;
      this.props.filterState.filterData.natcabuDate = null;
      this.props.filterState.filterData.buDate = null;
      this.props.filterState.filterData.unionStartDate = null;
      this.props.filterState.filterData.unionJoinDate = null;

      var apply = {
        searchType: 9,
        isApplied: true,
        isSearched: false,
        selectedItems: ["Active"],
      };
      this.props.filterState.filterData.searchApplied.push(apply);
      this.props.setFilterData({
        ...this.props.filterState.filterData,
      });

      if (this.props.filterState.isAdvancedFilterSelected) {
        this.onResetTable();
      }
      this.props.setAdvanceFilterLoader(true);
      this.props.getNewFilteredData(this.props.filterState.filterData, true);
      this.onReturnNo();
    }
  }

  componentDidMount() {
    this.SelectRowsOnload();
    var items = JSON.parse(localStorage.getItem("BucketSearch"));
    // this.orderColumn.hidden = !AuthService.canView(PermissionFeature.FacilityMemberTieBreaker);
    if (items !== null) {
      if (items.length > 0) {
        this.setState({ BCounter: items.length });
      }
    }

    if (this.props.location && this.props.location.lastPlace) {
      this.setState({ lastPlace: this.props.location.lastPlace });
    }

    //  NewCheckes After advncefilter

    ///console.clear();

    if (
      this.props.filterState.isAdvancedFilterSelected &&
      !this.props.facility &&
      !this.props.filterState.resetAdvanceFilter // && this.props.location.name === "Members"
    ) {
      if (
        !_.isEmpty(this.props.filterState.filterData.searchApplied) &&
        this.props.filterState.returnResultNo > 0
      ) {
        $(".loading").show();
        let SortOrder = "";
        if (localStorage.getItem("SortType") !== null) {
          this.setState({ SortOrder: localStorage.getItem("SortType") });
          SortOrder = localStorage.getItem("SortType");
        } else {
          SortOrder = this.state.SortOrder;
        }

        axios
          .post(
            `/api/Member/AdvancedFilter?pageOffset=${(this.props.filterState
              .currentFilterResultPage -
              1) *
            25}&pageFetch=25&sortName=${SortOrder}`,
            this.props.filterState.filterData
          )
          .then(({ data }) => {
            if (data.status && data.status.toLowerCase() == "success") {
              this.onAdvancedChangeTable(data);

              this.props.setFilterResult(data);
              this.props.setAdvancedSearch(true);
              this.props.setIsBasicSearchSelected(false);

              //$(".cancelBtn").click();
              this.props.filterState.filterData.searchApplied = this.props.filterState.filterData.searchApplied.map(
                (item) => {
                  if (item.isApplied) {
                    item.isSearched = true;
                  } else {
                    item.isSearched = false;
                  }
                  return item;
                }
              );
              this.props.setFilterData({
                ...this.props.filterState.filterData,
              });
            } else {
              console.log(data);
              toast.error(data.message);
            }
            $(".loading").hide();
          });
      }
    } else if (
      this.props.filterState.resetAdvanceFilter &&
      !_.isEmpty(this.props.filterState.filterData)
    ) {
      const { filterMembersTab } = this.props;
      if (!filterMembersTab) {
        //dont reset if Filter tab is opened
        this.resetALLFilters();
        this.props.setResetAdvanceFilter(false);
      }
    } else if (!_.isEmpty(this.props.filterState.filterData)) {
      //this.resetALLFilters();
    }

    if (
      this.props.filterState.isAdvancedFilterSelected === false &&
      this.props.SavedSearch.isSavedSearchSelected === false &&
      !this.props.facility &&
      this.props.filterState.basicSearchKeyWord &&
      this.props.filterState.basicSearchKeyWord !== "" &&
      this.state.totalCount === 0
    ) {
      if (this.props.SavedSearch.BucketSearchId == 0) {
        this.props.resetSavedSearch();
      }

      this.getMembers(this.props.filterState.basicSearchKeyWord, "", "");

      // this.setState({ keyword: this.props.filterState.basicSearchKeyWord });
    }
    this.setDefaultColumns();

    if (
      !_.isEmpty(this.props.location) &&
      _.isEmpty(this.props.filterState.filterData)
    ) {
      const params = new URLSearchParams(window.location.search);
      const facilityCode = params.get("facilityCode");
      const regionCode = params.get("regionCode");
      if (!_.isEmpty(facilityCode)) {
        this.facilityNavigation(facilityCode);
      }
      if (!_.isEmpty(regionCode)) {
        this.regionNavigation(regionCode);
      }
    }

    if (
      this.props.location &&
      this.props.location.state &&
      (this.props.location.state.routeFromProfile ||
        this.props.location.state.routeFromDDLFiler) &&
      localStorage.getItem("isMemberEditRowClick") == "false"
    ) {
      //debugger
      //console.log("triggered AF click");
      $("#clickAdvancedFilter").trigger("click");
    }
  }

  async facilityNavigation(facilityCode) {
    $(".loading").hide();
    this.setState({ memberGridLoad: true });
    axios
      .post("/api/Member/AdvancedFilteredData?getRefreshData=true", {})
      .then(async ({ data }) => {
        if (data.status && data.status.toLowerCase() == "success") {
          await this.props.setFilterData(data.data);
          await this.props.setTempFilterData(data.data);
          var facilityIndex = _.findIndex(
            this.props.filterState.filterData.facility,
            (o) => {
              return o.code === facilityCode;
            }
          );
          if (facilityIndex > -1) {
            this.props.filterState.filterData.facility[
              facilityIndex
            ].checked = true;
            await this.props.setFilterData({
              ...this.props.filterState.filterData,
            });
            await this.props.setActiveTab("8");
            await this.props.setCollapseTab("5");
            const currentTab = this.props.filterState.activeTab;

            var apply = {
              searchType: currentTab,
              isApplied: true,
              isSearched: true,
              selectedItems: [facilityCode],
            };

            this.props.filterState.filterData.searchApplied.push(apply);
            await this.props.setFilterData({
              ...this.props.filterState.filterData,
            });
            await this.props.setCurrentActiveTab(currentTab);
            await this.props.getNewFilteredData(
              this.props.filterState.filterData,
              false
            );

            $(".loading").hide();
            await this.props.getMemberTotalCount(
              this.props.filterState.filterData
            );
            this.facilityNavigationCall();
          }
        } else {
          this.setState({ memberGridLoad: null });
          toast.error(data.message);
        }
      });
  }
  async facilityNavigationCall() {
    await this.props.setFilterResultPageNo(1);
    await this.props.setFilterResultPageSize(25);
    axios
      .post(
        "/api/Member/AdvancedFilter?pageOffset=0&pageFetch=25&sortName=seniorityasc",
        this.props.filterState.filterData
      )
      .then(({ data }) => {
        if (data.status && data.status.toLowerCase() == "success") {
          this.onAdvancedChangeTable(data);
          this.props.setFilterResult(data);
          this.props.setAdvancedSearch(true);
          this.props.setIsBasicSearchSelected(false);
          this.props.filterState.filterData.searchApplied = !_.isEmpty(
            this.props.filterState.filterData.searchApplied
          )
            ? this.props.filterState.filterData.searchApplied.map((item) => {
              if (item.isApplied) {
                item.isSearched = true;
              } else {
                item.isSearched = false;
              }
              return item;
            })
            : null;
          this.props.setFilterData({
            ...this.props.filterState.filterData,
          });
          this.setState({ memberGridLoad: null });
        } else {
          toast.error(data.message);
          this.setState({ memberGridLoad: false });
        }
      });
  }

  async regionNavigation(regionCode) {
    $(".loading").hide();
    this.setState({ memberGridLoad: true });
    axios
      .post("/api/Member/AdvancedFilteredData?getRefreshData=true", {})
      .then(async ({ data }) => {
        if (data.status && data.status.toLowerCase() == "success") {
          await this.props.setFilterData(data.data);
          await this.props.setTempFilterData(data.data);

          var regionIndex = _.findIndex(
            this.props.filterState.filterData.region,
            (o) => {
              return o.code === regionCode;
            }
          );
          if (regionIndex > -1) {
            this.props.filterState.filterData.region[
              regionIndex
            ].checked = true;
            await this.props.setFilterData({
              ...this.props.filterState.filterData,
            });
            await this.props.setActiveTab("11");
            await this.props.setCollapseTab("5");
            const currentTab = this.props.filterState.activeTab;

            var apply = {
              searchType: currentTab,
              isApplied: true,
              isSearched: true,
              selectedItems: [regionCode],
            };

            this.props.filterState.filterData.searchApplied.push(apply);
            await this.props.setFilterData({
              ...this.props.filterState.filterData,
            });
            await this.props.setCurrentActiveTab(currentTab);
            await this.props.getNewFilteredData(
              this.props.filterState.filterData,
              false
            );

            $(".loading").hide();
            await this.props.getMemberTotalCount(
              this.props.filterState.filterData
            );
            this.regionNavigationCall();
          }
        } else {
          this.setState({ memberGridLoad: null });
          toast.error(data.message);
        }
      });
  }
  async regionNavigationCall() {
    await this.props.setFilterResultPageNo(1);
    await this.props.setFilterResultPageSize(25);
    axios
      .post(
        "/api/Member/AdvancedFilter?pageOffset=0&pageFetch=25&sortName=seniorityasc",
        this.props.filterState.filterData
      )
      .then(({ data }) => {
        if (data.status && data.status.toLowerCase() == "success") {
          this.onAdvancedChangeTable(data);
          this.props.setFilterResult(data);
          this.props.setAdvancedSearch(true);
          this.props.setIsBasicSearchSelected(false);
          this.props.filterState.filterData.searchApplied = !_.isEmpty(
            this.props.filterState.filterData.searchApplied
          )
            ? this.props.filterState.filterData.searchApplied.map((item) => {
                if (item.isApplied) {
                  item.isSearched = true;
                } else {
                  item.isSearched = false;
                }
                return item;
              })
            : null;
          this.props.setFilterData({
            ...this.props.filterState.filterData,
          });
          this.setState({ memberGridLoad: null });
        } else {
          toast.error(data.message);
          this.setState({ memberGridLoad: false });
        }
      });
  }
  componentWillUnmount() {
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.pathname &&
      this.props.history.location.pathname.indexOf("/userAction/") < 0 &&
      this.props.history.location.pathname !== "/directory/members"
    ) {
      this.props.setResetAdvanceFilter(true);
    }
    try {
      const isAFExists = $("#AdvanceFilterModal");
      if (!_.isEmpty(isAFExists) && isAFExists.length > 0) {
        isAFExists.hide();
        const modalDiv = $(".modal-backdrop");
        if (!_.isEmpty(modalDiv) && modalDiv.length > 0) {
          modalDiv.remove();
        }
      }
    } catch (err) {
      console.error("An error occurred: while handling unmount", err);
    }
  }

  setDefaultColumns = () => {
    const { columnsToView } = this.state;
    if (columnsToView && columnsToView.length > 0) {
      return;
    }
    var isFacilityDashboard =
      window.location.href.indexOf("facility") > -1 ? true : false;
    let url =
      "api/SavedSearch/GetDefaultColumns?isFacilityDashboard=" +
      isFacilityDashboard;
    axios.get(url).then(({ data: defaultColumns }) => {
      if (this.props.FacilityMembers === true) {
        var items = JSON.parse(
          localStorage.getItem("FMembers_" + this.state.memberId)
        );
        if (items !== null) {
          var filteredItems = items.filter(
            (x) => !defaultColumns.some((d) => d.id === x.id)
          );
          Array.prototype.push.apply(defaultColumns, filteredItems);
        }
      } else if (this.props.PACMembers === true) {
        var items = JSON.parse(
          localStorage.getItem("PAC_" + this.state.memberId)
        );
        if (items !== null) {
          var filteredItems = items.filter(
            (x) => !defaultColumns.some((d) => d.id === x.id)
          );
          Array.prototype.push.apply(defaultColumns, filteredItems);
        }
      } else {
        var items = JSON.parse(
          localStorage.getItem("Members_" + this.state.memberId)
        );
        if (items !== null) {
          var filteredItems = items.filter(
            (x) => !defaultColumns.some((d) => d.id === x.id)
          );
          let SeniorityColumns = [
            "entryOnDutyDate",
            "serviceComputationDate",
            "bargainingUnitDate",
            "natcaBargainingUnitDate",
          ];
          filteredItems = filteredItems.filter((x) => {
            if (
              SeniorityColumns.findIndex((el) => el == x.backend_column_name) >
              -1
            ) {
              return false;
            }
            return true;
          });
          Array.prototype.push.apply(defaultColumns, filteredItems);
        }
      }
      this.setState({ customizeColumns: defaultColumns });
      this.setColumnsHeaders(defaultColumns);
    });
    return;
  };
  onAdvancedChangeTable(data) {
    if (
      this.state.savedSearchId > 0 &&
      (this.state.savedSearchTypeId == SavedSearchTypes.BUCKET_ITEM ||
        (this.state.savedSearchTypeId === SavedSearchTypes.SHARED_ITEM &&
          this.state.sharedSaveSearchTypeId === SavedSearchTypes.BUCKET_ITEM))
    ) {
      this.setState({
        savedSearchDetail: null,
      });

      this.props.setSavedSearchDetail(null);
    }
    //console.log("onAdvancedChangeTable" + data);
    const { isAdvancedFilterOpenedInTab } = this.props.SavedSearch;

    if (isAdvancedFilterOpenedInTab) {
      this.setState({
        data: data.data,
        totalCount: data.totalCount,
        page: this.props.filterState.currentFilterResultPage,
        sizePerPage: 25,

        savedSearchCriteria: null,
        savedSearchId: 0,
        savedSearchTypeId: 0,
      });
    } else {
      this.setState({
        data: data.data,
        totalCount: data.totalCount,
        // page: 1,
        page: this.props.filterState.currentFilterResultPage,
        sizePerPage: 25,

        savedSearchCriteria: null,
        savedSearchId: 0,
        savedSearchTypeId: 0,
      });
    }

    this.setDefaultColumns();
  }
  onResetTable() {
    this.setState({
      data: [],
      totalCount: 0,
      page: 1,
      sizePerPage: 25,
    });
  }
  ViewAllLink = (event) => {
    event.preventDefault();

    $(".loading").show();
    if (this.props.facility) {
      let formData = {
        Keyword: "",
        PageNo: 1,
        Facility: this.state.facility,
        AreaId:
          this.state.areasCount > 0 ? parseInt(this.state.activeTabKey) : -1,
        Region: this.state.region,
        PageSize: this.state.totalCount,
        SortOrder: this.state.SortOrder,
        ShowOnlyActive: this.state.showActive,
      };

      console.log("ViewAllLink 1676");
      const url = `/api/member/SearchMembers`;
      axios.post(url, formData).then(({ data }) => {
        if (data.status && data.status.toLowerCase() == "success") {
          this.setState({
            data: data.data,
            totalCount: data.totalCount,
            page: 1,
            sizePerPage: this.state.totalCount,
          });
        } else {
          toast.error(data.message);
        }
        $(".loading").hide();
      });
    } else if (
      this.props.filterState.isAdvancedFilterSelected === false &&
      !this.props.facility &&
      this.props.filterState.basicSearchKeyWord &&
      this.props.filterState.basicSearchKeyWord !== ""
    ) {
      let formData = {
        Keyword: this.props.filterState.basicSearchKeyWord,
        PageNo: 1,
        Facility: "",
        AreaId:
          this.state.areasCount > 0 ? parseInt(this.state.activeTabKey) : -1,
        PageSize: this.state.totalCount,
        SortOrder: this.state.SortOrder,
        ShowOnlyActive: this.state.showActive,
      };
      console.log("ViewAllLink 1705");
      const url = `/api/member/SearchMembers`;
      axios.post(url, formData).then(({ data }) => {
        if (data.status && data.status.toLowerCase() == "success") {
          this.setState({
            data: data.data,
            totalCount: data.totalCount,
            page: 1,
            sizePerPage: this.state.totalCount,
          });
        } else {
          toast.error(data.message);
        }
        $(".loading").hide();
      });
    } else if (this.props.filterState.isAdvancedFilterSelected) {
      if (!_.isEmpty(this.props.filterState.filterData)) {
        var offset = (1 - 1) * this.state.totalCount;
        $(".loading").show();
        const url =
          "/api/Member/AdvancedFilter?pageOffset=" +
          offset +
          "&pageFetch=" +
          this.state.totalCount +
          "&sortName=" +
          this.state.SortOrder;
        this.setState({ ApiHit: Constants.ADVANCE_SEARCH });
        axios.post(url, this.props.filterState.filterData).then(({ data }) => {
          if (data.status && data.status.toLowerCase() == "success") {
            this.setState({
              data: data.data,
              totalCount: data.totalCount,
              page: 1,
              sizePerPage: data.totalCount,
            });

            this.props.setFilterResult(data);
            this.props.setAdvancedSearch(true);
            this.props.setIsBasicSearchSelected(false);
          } else {
            console.log(data);
            toast.error(data.message);
          }
          $(".loading").hide();
        });
      }
    }

    return this.state.totalCount;
  };

  //FOR CSV
  onCSVClick = async () => {
    this.setState({
      disableIcon: "disableDownloadIcons",
    });
    toast.success(this.state.waitingMessage);
    const isBasicSearched =
      !this.state.savedSearchId &&
      this.props.filterState.isAdvancedFilterSelected === false &&
      !this.props.facility &&
      this.props.filterState.basicSearchKeyWord &&
      this.props.filterState.basicSearchKeyWord !== "";
    const isBucketSearch =
      this.state.savedSearchId > 0 &&
      (this.state.savedSearchTypeId == SavedSearchTypes.BUCKET_ITEM ||
        (this.state.savedSearchTypeId === SavedSearchTypes.SHARED_ITEM &&
          this.state.sharedSaveSearchTypeId === SavedSearchTypes.BUCKET_ITEM));
    if (isBucketSearch) {
      let data = this.state.displayMembers;
      await this.setState({
        downloadData: this.state.displayMembers,
      });
      //onCSVClick
      this.RemotePagination(
        this.state.downloadData,
        false,
        false,
        this.handleTableChange,
        false,
        false
      );

      const dataTable = document.getElementById("pdfdiv");
      //const btnExportToCsv = document.getElementById("btnExportToCsv");
      const exporter = new TableCSVExporter(dataTable);
      const csvOutput = exporter.convertToCSV();
      const csvBlob = new Blob([csvOutput], {
        type: "text/csv",
      });
      const blobUrl = URL.createObjectURL(csvBlob);
      const anchorElement = document.createElement("a");

      anchorElement.href = blobUrl;
      anchorElement.download = "Exported-Result.csv";
      anchorElement.click();
      this.hideSpinner();
      this.setState({
        disableIcon: "",
      });
    } else {
      this.showSpinner();
      let url = "";
      let body = "";
      // const url = `/api/SavedSearch/AdvancedFilterForPDF?totalrecords=${this.state.totalCount}`;
      // const body = { ...this.props.filterState.filterData };
      if (this.state.ApiHit === Constants.OTHERS) {
        url = this.state.ApiUrl;
        body = this.state.ApiCriteria;
        body.PageSize =
          this.state.activeTotalCount + this.state.inactiveTotalCount;
        body.ShowBoth = true;
      } else {
        url = `/api/SavedSearch/AdvancedFilterForPDF?totalrecords=${this.state.totalCount}`;
        body = { ...this.props.filterState.filterData };
      }
      var flag = false;
      await axios.post(url, body).then(({ data }) => {
        if (data.status && data.status.toLowerCase() == "success") {
          this.setState({
            downloadData: data.data,
          });
          //onCSVClick
          this.RemotePagination(
            this.state.downloadData,
            false,
            false,
            this.handleTableChange,
            false,
            false
          );

          const dataTable = document.getElementById("pdfdiv");
          //const btnExportToCsv = document.getElementById("btnExportToCsv");
          const exporter = new TableCSVExporter(dataTable);
          const csvOutput = exporter.convertToCSV();
          const csvBlob = new Blob([csvOutput], {
            type: "text/csv",
          });
          const blobUrl = URL.createObjectURL(csvBlob);
          const anchorElement = document.createElement("a");

          anchorElement.href = blobUrl;
          anchorElement.download = "Exported-Result.csv";
          anchorElement.click();

          // setTimeout(() => {
          //   URL.revokeObjectURL(blobUrl);
          // }, 500);
        } else {
          toast.error(data.message);
        }

        this.hideSpinner();
        this.setState({
          disableIcon: "",
        });
      });
    }
  };

  onPDFClick = async () => {
    this.showSpinner();
    this.setState({
      disableIcon: "disableDownloadIcons",
    });
    toast.success(this.state.waitingMessage);
    const isBasicSearched =
      !this.state.savedSearchId &&
      this.props.filterState.isAdvancedFilterSelected === false &&
      !this.props.facility &&
      this.props.filterState.basicSearchKeyWord &&
      this.props.filterState.basicSearchKeyWord !== "";

    const isBucketSearch =
      this.state.savedSearchId > 0 &&
      (this.state.savedSearchTypeId == SavedSearchTypes.BUCKET_ITEM ||
        (this.state.savedSearchTypeId === SavedSearchTypes.SHARED_ITEM &&
          this.state.sharedSaveSearchTypeId === SavedSearchTypes.BUCKET_ITEM));
    if (isBasicSearched) {
      const { recordsPerpage, SortOrder, searchCriteria } = this.state;
      let url = "";
      let body = "";
      // const url = `/api/SavedSearch/AdvancedFilterForPDF?totalrecords=${this.state.totalCount}`;
      // const body = { ...this.props.filterState.filterData };
      if (this.state.ApiHit === Constants.OTHERS) {
        url = this.state.ApiUrl;
        body = this.state.ApiCriteria;
        body.PageSize =
          this.state.activeTotalCount + this.state.inactiveTotalCount;
        body.ShowBoth = true;
      } else {
        url = `/api/SavedSearch/AdvancedFilterForPDF?totalrecords=${this.state.totalCount}`;
        body = { ...this.props.filterState.filterData };
      }
      await axios.post(url, body).then(({ data }) => {
        if (data.status && data.status.toLowerCase() == "success") {
          var activedata = data.data.filter((m) => {
            if (
              m.status.toLowerCase() === "active" ||
              m.status.toLowerCase() === "retired"
            )
              return m;
          });
          var inactivedata = data.data.filter((m) => {
            if (
              m.status.toLowerCase() !== "active" &&
              m.status.toLowerCase() !== "retired"
            )
              return m;
          });
          this.setState({
            downloadDataActive: activedata,
            downloadDataInActive: inactivedata,
          });

          // this.RemotePagination(
          //   this.state.downloadData,
          //   false,
          //   false,
          //   this.handleTableChange,
          //   false,
          //   false
          // );

          this._html2canvasbasicsearch();

          {
            /* setTimeout(() => {
                                                                                                      this._html2canvas();
                                                                                                    }, 10000);*/
          }

          this.hideSpinner();
        } else {
          toast.error(data.message);
        }
      });
    } else {
      if (this.state.data) {
        await this.setState({
          downloadData: this.state.data,
        });

        //onPDFClick
        this.RemotePagination(
          this.state.downloadData,
          false,
          false,
          this.handleTableChange,
          false,
          false
        );

        setTimeout(() => {
          this._html2canvas();
          this.hideSpinner();
        }, 3000);
      }
    }
  };

  onBucketSearch = () => {
    var items = JSON.parse(localStorage.getItem("BucketSearch"));
    this.setState({ displayMembers: items, showBucketModal: true });
  };

  onEmail = () => {
    this.setState({ showModal: true, check: CompositionModalMode.Email });

    console.log(this.state.data);
    const rowsToAdd = [];
    let bucketcheck = JSON.parse(localStorage.getItem("BucketSearch"));
    if (_.isEmpty(bucketcheck)) {
      for (let i = 0; i < this.state.data.length; i++) {
        if (this.state.data[i].emailAddress != "") {
          if (this.state.data[i].allowEmails === true)
            rowsToAdd.push(this.state.data[i].emailAddress);
        }
      }
    } else if (!_.isEmpty(bucketcheck)) {
      var bucket = JSON.parse(localStorage.getItem("BucketSearch"));
      for (let i = 0; i < bucket.length; i++) {
        if (bucket[i].emailAddress != "") {
          if (bucket[i].allowEmails === true) {
            rowsToAdd.push(bucket[i].emailAddress);
          }
        }
      }
    }

    if (rowsToAdd.length > 0) {
      let selectedEmails = [...new Set(rowsToAdd)];
      this.setState({ emails: selectedEmails });
    } else {
      toast.warn("Selected members are not allowed to have email alerts.");
      this.setState({ showModal: false });
    }
  };

  onSMS = () => {
    const rowsToAdd = [];
    this.setState({ showModal: true, check: CompositionModalMode.SMS });
    let bucketcheck = localStorage.getItem("BucketSearch");
    if (bucketcheck === null) {
      for (let i = 0; i < this.state.data.length; i++) {
        if (this.state.data[i].verifiedNumber != "") {
          if (this.state.data[i].allowTextMessages === true) {
            rowsToAdd.push(this.state.data[i].verifiedNumber);
          }
        }
      }
    } else if (localStorage.getItem("BucketSearch") !== null) {
      var bucket = JSON.parse(localStorage.getItem("BucketSearch"));
      for (let i = 0; i < bucket.length; i++) {
        if (bucket[i].verifiedNumber != "") {
          if (bucket[i].allowTextMessages != "") {
            rowsToAdd.push(bucket[i].verifiedNumber);
          }
        }
      }
    }

    if (rowsToAdd.length > 0) {
      let selectedNumbers = [...new Set(rowsToAdd)];
      this.setState({ emails: selectedNumbers });
    } else {
      toast.warn("Selected members are not allowed to have text alerts.");
      this.setState({ showModal: false });
    }
    this.setState({ emails: rowsToAdd });
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };

  hideBucketModal = () => {
    this.setState({ showBucketModal: false });
  };

  _html2canvas = () => {
    $("#pdfdiv")
      .find(".react-bootstrap-table-pagination")
      .css("display", "none");

    const input = document.getElementById("pdfdiv");
    var w = document.getElementById("pdfdiv").offsetWidth;
    var h = document.getElementById("pdfdiv").offsetHeight;
    $("#pdfdiv").css("display", "");
    html2canvas(input).then((canvas) => {
      this.showSpinner();
      var imgData = canvas.toDataURL("image/png");
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF("p", "mm");
      var position = 0;

      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      $("#pdfdiv").css("display", "none");
      doc.save("file.pdf");
      this.hideSpinner();
      this.setState({
        disableIcon: "",
      });
      // var img = canvas.toDataURL("image/png");
      // var imgWidth = 210;
      // var pageHeight = 295;
      // var imgHeight = (canvas.height * imgWidth) / canvas.width;
      // var heightLeft = imgHeight;
      // var doc = new jsPDF("p", "mm");
      // var position = 0;

      // doc.addImage(img, "PNG", 0, position, w, h);
      // heightLeft -= pageHeight;

      // while (heightLeft >= 0) {
      //   position = heightLeft - imgHeight;
      //   doc.addPage();
      //   doc.addImage(img, "PNG", 0, position, w, h);
      //   heightLeft -= pageHeight;
      // }
      // doc.save("Exported-Result.pdf");
    });
  };

  _html2canvasbasicsearch = () => {
    $("#basicsearchpdfdiv")
      .find(".react-bootstrap-table-pagination")
      .css("display", "none");

    const input = document.getElementById("basicsearchpdfdiv");
    var w = document.getElementById("basicsearchpdfdiv").offsetWidth;
    var h = document.getElementById("basicsearchpdfdiv").offsetHeight;
    $("#basicsearchpdfdiv").css("display", "");
    html2canvas(input).then((canvas) => {
      this.showSpinner();
      var imgData = canvas.toDataURL("image/png");
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF("p", "mm");
      var position = 0;

      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      $("#basicsearchpdfdiv").css("display", "none");
      doc.save("file.pdf");
      this.hideSpinner();
      this.setState({
        disableIcon: "",
      });
    });
  };

  triggerFirstRowClick = (row) => {
    if (this.props.facility) {
      this.props.his.replace({
        pathname: "/directory/userAction/" + row.id,
        type: "edit",
        uid: row.id,
        keyword: $("#keyword").val(),
        showActive: this.state.showActive,
        page: this.state.page,
        backRef: {
          uid: this.props.his.location.uid,
          backRef: {
            uid: this.props.his.location.backRef
              ? this.props.his.location.backRef.uid
              : undefined,
          },
        },
      });
    } else {
      //  console.log("row click member");
      if (!_.isEmpty($("#keyword").val())) {
        this.props.setBasicSearchKeyWord($("#keyword").val());
      } else {
        $("#keyword").val(this.props.filterState.basicSearchKeyWord);
        // console.log("keyword is undefined");
      }

      this.props.history.push({
        pathname: "/directory/userAction/" + row.id,
        type: "edit",
        uid: row.id,

        keyword: $("#keyword").val(),
        showActive: this.state.showActive,
      });
    }
  };
  canSendEmail = () => {
    return AuthService.canView(Permissions.Email);
  };

  canSendSMS = () => {
    return AuthService.canView(Permissions.Text);
  };

  removeSelected = (id) => {
    this.setState((prevState) => ({
      displayMembers: prevState.displayMembers.filter((el) => el.id != id),
    }));

    var index = this.state.selectedRows.indexOf(id);
    if (index !== -1) {
      this.state.selectedRows.splice(index, 1);
      this.setState({ selectedRows: this.state.selectedRows });
    }

    this.setState((prevState) => {
      return { BCounter: prevState.BCounter - 1 };
    });

    var items = JSON.parse(localStorage.getItem("BucketSearch"));
    var filteredItems = items.filter((x) => x.id != id);
    localStorage.setItem("BucketSearch", JSON.stringify(filteredItems));

    axios
      .delete(`/api/SavedSearch/DeleteBucketResult?memberId=${id}`)
      .then(({ data }) => {
        if (data.status && data.status.toLowerCase() == "success") {
        } else {
        }
      });
  };

  EmptyBucket = () => {
    localStorage.removeItem("BucketSearch");
    this.setState({ BCounter: 0, displayMembers: [], selectedRows: [] });
    // this.setState((state) => ({ selected: [] }));
  };

  filterMembers = (Name) => {
    console.log(this.state.displayMembers);
    if (Name != "") {
      this.setState((prevState) => ({
        displayMembers: prevState.displayMembers.filter(
          (el) => el.firstName.includes(Name) || el.lastName.includes(Name)
        ),
      }));
    } else {
      this.setState({
        displayMembers: this.state.displayMembers,
      });
    }
  };

  canViewSavedSearch = () => {
    console.log(this.props.location);
    let isMemberPath = false;
    if (this.props.location && this.props.location.pathname) {
      isMemberPath =
        this.props.location.pathname.indexOf("/directory/members") != -1;
    }

    return AuthService.canView(Permissions.Saved_Search) && isMemberPath;
  };

  canViewSavedSearchCustomizer = () => {
    return AuthService.canView(Permissions.Customize_Fields);
  };

  canViewBucketResult = () => {
    return AuthService.canView(Permissions.Buckets);
  };

  canViewExportResults = () => {
    return AuthService.canView(Permissions.Export_Results);
  };

  iterator = (currentPage, list, paging) => {
    list = JSON.parse(localStorage.getItem("BucketSearch"));
    var current = currentPage,
      l = list.length;
    var end = paging;

    let skip = (currentPage - 1) * paging;
    if (skip < 0) {
      skip = 0;
    }
    let take = skip + paging;
    let temp = [...list];

    temp = temp.slice(skip, take);

    var part = temp;
    currentPage = end < l ? end : 0;
    this.setState({
      data: part,
      page: current,
      sizePerPage: paging,
      totalCount: list.length,
    });
    this.props.setFilterResultPageNo(current);
    //this.props.setFilterResultPageSize(paging);
    // return part;
  };
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  viewAllbucket = () => {
    this.props.setBasicSearchKeyWord("");
    // this.onResetTable();
    this.props.SetlocalPaging();
    //alert(this.props.filterState.localPaging);
    var _data = JSON.parse(localStorage.getItem("BucketSearch"));
    this.setState({ data: [] });
    this.setState({
      local: true,
      data: JSON.parse(localStorage.getItem("BucketSearch")),
      page: 1,
      sizePerPage: 25,
      totalCount: _data.length,
    });

    const { data, sizePerPage, page } = this.state;
    //viewAllbucket
    this.RemotePagination(
      data,
      page,
      sizePerPage,
      this.iterator(page, data, sizePerPage),
      data.length,
      this.rowEvents,
      this.state.selectedFields
    );
  };

  canBeSubmitted() {
    return this.state.advanced_search_title.length > 0;
  }
  redirectToFiltersList = () => {
    const currentLocation = window.location.pathname;
    this.props.history.push("/directory/myfilters", { reloadMyFilters: true });
    this.props.history.location.lastPlace = currentLocation;
  };
  saveSearch = (e) => {
    let postUrl = "/api/SavedSearch/SaveSearch";

    const body = {
      SearchName: this.state.advanced_search_title,
      SearchType: 1,
      MemberId: this.state.memberId,
      SearchCriteria: this.props.filterState.filterData,
    };
    this.showSpinner();
    axios.post(postUrl, body).then(({ data }) => {
      this.hideSpinner();
      if (data.status) {
        if (data.status.toLowerCase() === "success") {
          this.props.updateAndSelect(data.newSearchId, true);
          this.state.advanced_search_title = "";
          toast.success(data.message);
          this.redirectToFiltersList(); //Redirect to My Filters Listing after creating new Filter
        } else {
          toast.error(data.message);
        }
      } else {
        toast.error("Search cannot be saved.");
      }
    });
  };

  UpdateSaveSearch = (e) => {
    let postUrl = "/api/SavedSearch/UpdateSavedSearch";
    const { SavedSearchDetail } = this.props.SavedSearch;
    let filterId = 0;
    if (SavedSearchDetail.real_search_type_id) {
      //Shared SS
      filterId = SavedSearchDetail.saved_search_id;
    } else {
      filterId = SavedSearchDetail.id;
    }
    const body = {
      SearchName: SavedSearchDetail.search_name,
      SearchType: SavedSearchDetail.search_type_id,
      MemberId: this.state.memberId,
      SearchCriteria: this.props.filterState.filterData,
      id: filterId,
      totalrecords: this.state.totalCount,
    };

    this.showSpinner();
    axios.post(postUrl, body).then(({ data }) => {
      this.hideSpinner();
      if (data.status) {
        if (data.status.toLowerCase() === "success") {
          this.props.setAdvancedSearch(false); /// Exclude Advanced Filer flow
          this.props.setSavedSearchCriteria(null);

          //Dont want to refesh the Members tab because there are plenty of API call
          toast.success(data.message);
          const { filterResult } = this.props.filterState;
          if (filterResult && filterResult.totalCount > 250) {
            this.props.toggleEditFilterModal({}, false); //Just close the filter modal
            //and Reopen by clicking eye icon from Filters listing
          } else {
            this.props.updateAndSelect(filterId, true); //(id,refreh)
          }
        } else {
          toast.error(data.message);
        }
      } else {
        toast.error("Search cannot be updated.");
      }
    });
  };
  async createAreaTeam(e, id) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    let data = await AreasService.CreateAreaTeam(id);
    if (data) {
      await this.getAreas();
    }
  }
  getRecipientFieldValue = () => {
    // this.props.FacilityMembers
    //   ? MailGunEmailTypes.Facility
    //   : this.props.SavedSearch.isSavedSearchSelected &&
    //     this.props.melstate.savedSearchDllObject.savedSearchPerformed
    //   ? MailGunEmailTypes.Member
    //   : 0;

    ///CompositionModalMode.Email => ([{Bucket}(Raw Emails CSV && No Additional Mem)] || [{Facility || Region+Registrations || Team || }(Lists CSV+Plus additional Members)])
    const {
      isAdvancedFilterSelected,
      isBasicSearchSelected,
    } = this.props.filterState;
    //const { isSavedSearchSelected } = this.props.SavedSearch;

    let result = [];
    let isBucket = !_.isEmpty(JSON.parse(localStorage.getItem("BucketSearch")));
    if (isBucket) {
      // Bucket is not Empty
      let arrBucketListEmails =
        JSON.parse(localStorage.getItem("BucketSearch")) != null
          ? JSON.parse(localStorage.getItem("BucketSearch")).map(
            (m) => m.emailAddress
          )
          : [];
      result =
        this.state.check == CompositionModalMode.Email
          ? arrBucketListEmails
          : this.state.emails;
    } else {
      //Bucket is Empty => Could be BS Results or Advanced Search Results OR Mailing list for FRT+SS

      if (this.state.check == CompositionModalMode.Email) {
        if (isAdvancedFilterSelected || isBasicSearchSelected) {
          result = [...this.state.emails];
        } else {
          result = [
            ...this.state.emails,
            ...this.props.melstate.addtlMemEmails,
          ];
        }
      } else if (this.state.check == CompositionModalMode.SMS) {
        result = [...this.state.emails];
      }
    }

    return result;
  };
  render() {
    //console.log(
    //    !_.isEmpty(this.state.searchCriteria) &&
    //    !_.isEmpty(this.props.filterState.filterData) &&
    //    JSON.stringify(this.props.filterState.filterData.searchApplied) !=
    //    JSON.stringify(this.state.searchCriteria.searchApplied),
    //    "CC"
    //);
    //console.log(!_.isEmpty(this.state.searchCriteria), "SSC");
    //console.log(!_.isEmpty(this.props.filterState.filterData), "AF");
    ////console.log((JSON.stringify(this.props.filterState.filterData.searchApplied) != JSON.stringify(this.state.savedSearchCriteria.searchApplied)),'AF SSC');

    let { IconEvents } = this.props.SavedSearch;
    var selectRowProp = {
      mode: "checkbox",
      clickToSelect: true,
      selected: this.state.selectedRows,
      onSelectAll: this.onSelectAll,
      onSelect: this.onSelectSingle,
      hideSelectAll: this.canViewBucketResult() === true ? false : true,
      hideSelectColumn: this.canViewBucketResult() === true ? false : true,
    };
    const {
      data,
      sizePerPage,
      page,
      totalCount,
      pacSumDonation,
      pacCoutNonZero,
      savedSearchDetail,
    } = this.state;
    let selectedFields = this.state.selectedFields;
    if (this.state.savedSearchId && this.state.savedSearchId > 0) {
      selectedFields = [];
    }
    this.LoadPacColumn();
    const showTotal = true;
    const isEnabled = this.canBeSubmitted();
    const isSearchCriteriaEmpty = _.isEmpty(this.state.searchCriteria);
    const isFilterDataEmpty = _.isEmpty(this.props.filterState.filterData);
    const shouldRenderSaveChangesButton =
      savedSearchDetail &&
      !isSearchCriteriaEmpty &&
      !isFilterDataEmpty &&
      savedSearchDetail.search_type_id !== BUCKET_ITEM &&
      !(
        savedSearchDetail.search_type_id === SavedSearchTypes.SHARED_ITEM &&
        this.state.sharedSaveSearchTypeId === SavedSearchTypes.BUCKET_ITEM
      ) &&
      JSON.stringify(this.props.filterState.filterData.searchApplied) !==
      JSON.stringify(this.state.searchCriteria.searchApplied);
    const shouldShowTags =
      (this.props.filterState.isAdvancedFilterSelected ||
        this.state.savedSearchId > 0) &&
      this.state.savedSearchTypeId != SavedSearchTypes.BUCKET_ITEM &&
      !(
        this.state.savedSearchTypeId === SavedSearchTypes.SHARED_ITEM &&
        this.state.sharedSaveSearchTypeId === SavedSearchTypes.BUCKET_ITEM
      );
    const disableTags =
      this.props.filterMembersTab &&
      !AuthService.canUpdate(Permissions.Saved_Search);
    return (
      <AUX>
        <div className="container-fluid">
          {AuthService.canView(PermissionFeature.PacSummary) &&
            totalCount > 0 &&
            this.props.isPAC &&
            !this.props.facilityDashboardState.IsMemberAccordianLoading && (
              <FacilityPAC
                handleSearchClick={(e) => this.handlePACSearch(e)}
                pacMemberSearchTxt={this.state.pacMemberSearchTxt}
                pacMemberSearchTxt_err={this.state.pacMemberSearchTxt_err}
                searchTextChangeHandler={this.searchTextChangeHandler}
                searchTextKeyupHandler={this.searchTextKeyupHandler}
                AmountPerPayPeriod={pacSumDonation}
                CountTotalMembers={totalCount}
                PACCount={pacCoutNonZero}
              />
            )}
          {shouldShowTags && (
            <div
              className={
                this.props.filterState.isAdvancedFilterSelected &&
                  !this.props.facility
                  ? "row memberpage-tags pt-2"
                  : this.props.SavedSearch.isOpenFilterModal
                    ? "pl-1 row"
                    : "row"
              }
            >
              <Tags
                RefreshResult={true}
                {...this.props}
                onChangeTable={this.onAdvancedChangeTable.bind(this)}
                onResetTable={this.onResetTable.bind(this)}
                disableTags={disableTags}
              />
            </div>
          )}
          <div
            className={`row mx-0 w-100 align-items-center mb-2 ${shouldShowTags ? "" : "pt-3 mt-4"
              } ${this.props.isEditFilterModal ? "" : shouldShowTags ? "mt-2" : ""
              }`}
          // style={{ marginBottom: 1 + "em" }}
          >
            <div className="col-sm-6 pl-sm-0 d-flex align-items-center">
              {this.props.filterState.basicSearchKeyWord === "" && (
                <>
                  {savedSearchDetail && (
                    <>
                      {/* <h4 className="text-info align-self-baseline font-weight-bold mr-2 my-0 d-inline">
                        {savedSearchDetail.search_name}
                      </h4>
                      {savedSearchDetail.is_pinned === 1 ? (
                        <i
                          title={`Pin ${savedSearchDetail.search_name}.`}
                          className="fas mr-1 c-pointer mt-1 fa-star"
                          onClick={() => {
                            this.props.setIconEvent(IconEvents.STAR_ICON);
                          }}
                        ></i>
                      ) : (
                        <i
                          title={`UnPin ${savedSearchDetail.search_name}.`}
                          className="far mr-1 c-pointer mt-1 fa-star"
                          onClick={() => {
                            this.props.setIconEvent(IconEvents.STAR_ICON);
                          }}
                        ></i>
                      )} */}
                      {/* {savedSearchDetail.member_id === this.state.memberId && (
                        <i
                          className="fas mr-1 mt-1 c-pointer fa-pencil-alt"
                          title={`Edit ${savedSearchDetail.search_name}.`}
                          onClick={() => {
                            this.props.setIconEvent(IconEvents.EDIT_ICON);
                          }}
                        ></i>
                      )} */}
                      {/* {(savedSearchDetail.member_id === this.state.memberId ||
                        savedSearchDetail.search_type_id == SHARED_ITEM) &&
                        AuthService.canDelete(Permissions.Saved_Search) && (
                          <i
                            className="fas mr-1 c-pointer text-danger fa-trash-alt"
                            title={`Delete ${savedSearchDetail.search_name}.`}
                            onClick={() => {
                              this.props.setIconEvent(IconEvents.DELETE_EVENT);
                            }}
                          ></i>
                        )} */}
                      {/* <i
                        className="fas mr-1 mt-1 c-pointer fa-times"
                        onClick={() => {
                          this.props.resetSavedSearch();
                        }}
                      ></i> */}
                      {!_.isEmpty(this.state.searchCriteria) &&
                        !_.isEmpty(this.props.filterState.filterData) &&
                        savedSearchDetail.search_type_id != BUCKET_ITEM &&
                        !(
                          savedSearchDetail.search_type_id ===
                          SavedSearchTypes.SHARED_ITEM &&
                          this.state.sharedSaveSearchTypeId ===
                          SavedSearchTypes.BUCKET_ITEM
                        ) &&
                        JSON.stringify(
                          this.props.filterState.filterData.searchApplied
                        ) !=
                        JSON.stringify(
                          this.state.searchCriteria.searchApplied
                        ) && (
                          <button
                            className="btn btn-sm btn-success rounded-pills"
                            onClick={this.UpdateSaveSearch.bind(this)}
                          >
                            Update Filter
                          </button>
                        )}
                    </>
                  )}
                  {AuthService.canAdd(Permissions.Saved_Search) &&
                    this.props.filterState.isAdvancedFilterSelected &&
                    this.state.savedSearchDetail == null && (
                      <>
                        <input
                          type="text"
                          name="advanced_search_title"
                          id="advanced_search_title"
                          className="d-inline w-auto mr-2 form-control"
                          placeholder="Name Search"
                          onChange={this.onChange.bind(this)}
                          value={this.state.advanced_search_title}
                        ></input>

                        <button
                          type="button"
                          className="btn btn-sm btn-success rounded-pills"
                          disabled={!isEnabled}
                          onClick={this.saveSearch.bind(this)}
                        >
                          Save Filter
                        </button>
                      </>
                    )}
                </>
              )}
            </div>

            {/* Member Listing Accordion Toolbar Starts */}
            <div className="col-sm-6 pr-sm-0 text-right inline-toolbar-item">
              {this.canSendEmail() === true &&
                this.state.data != null &&
                this.state.data.length > 0 && (
                  <i
                    class="fas fa-envelope"
                    onClick={this.onEmail}
                    title={`Send email to members ${this.state.data.length}`}
                  ></i>
                )}
              {this.canSendSMS() === true &&
                this.state.data != null &&
                this.state.data.length > 0 && (
                  <i
                    class="fas fa-sms"
                    onClick={this.onSMS}
                    title="Send SMS to members"
                  ></i>
                )}
              {this.canViewExportResults() === true &&
                this.state.data != null &&
                this.state.data.length > 0 && (
                  <span className={this.state.disableIcon}>
                    <i
                      class="fas fa-file-pdf"
                      onClick={() => this.onPDFClick()}
                    ></i>

                    <i
                      class="fas fa-file-csv"
                      onClick={() => this.onCSVClick()}
                    ></i>
                  </span>
                )}
              {this.canViewBucketResult() === true &&
                this.state.data != null &&
                this.state.data.length > 0 && (
                  <i
                    class={
                      "fab fa-bitbucket notification" +
                      (this.state.BCounter > 0 ? " text-info" : "")
                    }
                    onClick={() => this.onBucketSearch()}
                  >
                    <span class="badge">{this.state.BCounter}</span>
                  </i>
                )}
              {this.state.data &&
                this.props.facility &&
                this.state.data.length > 0 && (
                  <i
                    className="fas fa-filter"
                    style={{ fontSize: 18 }}
                    title="Advance Filter"
                    onClick={() => {
                      window.open(
                        "/directory/members?facilityCode=" +
                        this.state.facility,
                        "_blank"
                      );
                    }}
                  ></i>
                )}
              {this.state.data != null &&
                this.props.region &&
                this.state.data.length > 0 && (
                  <i
                    className="fas fa-filter"
                    style={{ fontSize: 18 }}
                    title="Advance Filter"
                    onClick={() => {
                      window.open(
                        "/directory/members?regionCode=" + this.state.region,
                        "_blank"
                      );
                    }}
                  ></i>
                )}
              {this.canViewSavedSearchCustomizer() === true &&
                (this.state.savedSearchId > 0 ||
                  this.state.data.length > 0) && (
                  <SavedSearchCustomizer
                    FacilityDashboard={this.props.FacilityDashboard}
                    Facility={this.state.facility}
                    savedSearchId={this.state.savedSearchId}
                    searchTitle={this.state.searchTitle}
                    //searchType={this.state.searchType}
                    searchType={this.state.savedSearchTypeId}
                    forceSearchRefresh={(forceRefresh, selectedFields) => {
                      if (
                        !this.state.savedSearchId ||
                        this.state.savedSearchId <= 0
                      ) {
                        this.setColumnsHeaders(selectedFields);
                        const {
                          data,
                          sizePerPage,
                          page,
                          totalCount,
                          pacSumDonation,
                          pacCoutNonZero,
                        } = this.state;
                        //  canViewSavedSearchCustomizer
                        this.RemotePagination(
                          data,
                          1,
                          25,
                          false,
                          false,
                          this.rowEvents
                        );
                        return;
                      }
                      this.props.setSavedSearchCriteria(null);
                      this.props.updateAndSelect(
                        this.props.SavedSearch.SavedSearchDetail.id,
                        true
                      );
                    }}
                    selectedFields={selectedFields}
                    PACMembers={
                      this.props.PACMembers ? this.props.PACMembers : false
                    }
                    FacilityMembers={
                      this.props.FacilityMembers
                        ? this.props.FacilityMembers
                        : false
                    }
                  ></SavedSearchCustomizer>
                )}
            </div>
            {/* Member Listing Accordion Toolbar Ends */}
          </div>

          {this.props.FacilityMembers ? (
            this.state.areasCount > 0 ? (
              <Tabs
                defaultActiveKey="area1"
                id="uncontrolled-tab-example"
                className=""
                activeKey={this.state.activeTabKey}
                onSelect={(k) => this.setActiveTab(k)}
              >
                {this.state.areasList.map((item, index) => (
                  <Tab
                    eventKey={item.id}
                    title={
                      <>
                        <span className="font-weight-bold">
                          {" "}
                          {item.code}{" "}
                          {AuthService.canView(PermissionFeature.TeamsTeams) &&
                            item.id > 0 && (
                              <>
                                {item.teamid != null && item.teamid != -1 ? (
                                  <a
                                    alt="show Teams"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      e.nativeEvent.stopImmediatePropagation();
                                    }}
                                    href={
                                      "/permissions/teams/teamDetail/" +
                                      item.teamid
                                    }
                                  >
                                    <span className="fas fa-sm fa-users"></span>
                                  </a>
                                ) : (
                                  AuthService.canAdd(
                                    PermissionFeature.TeamsTeams
                                  ) /*&&
                                    !this.state.bulkProgress*/ && (
                                    <span
                                      alt="Create Team"
                                      onClick={(e) => {
                                        this.createAreaTeam(e, item.id);
                                      }}
                                      className="text-primary fas fa-sm fa-plus-circle"
                                    ></span>
                                  )
                                )}
                              </>
                            )}
                        </span>
                      </>
                    }
                  >
                    <div className="row">
                      <div
                        className="col-12"
                        style={{ minHeight: "fit-content" }}
                      >
                        {totalCount == 0 && !this.props.facility && (
                          <div className="memberpage-tags pt-2">
                            <p>
                              In the search field enter one of the following:
                            </p>
                            <ul>
                              <li>First Name</li>
                              <li>Last Name</li>
                              <li>Member Number</li>
                            </ul>
                          </div>
                        )}

                        {totalCount > 0 &&
                          (!this.props.facilityDashboardState
                            .IsMemberAccordianLoading ||
                            this.props.filterState.isAdvancedFilterSelected ||
                            this.state.savedSearchId > 0) && (
                            <Fragment>
                              {/* //When advance filter and save search both selected */}
                              {this.RemotePagination(
                                data,
                                this.props.filterState.currentFilterResultPage,
                                sizePerPage,
                                this.handleTableChange,
                                totalCount,
                                showTotal,
                                this.rowEvents,
                                selectedFields,
                                selectRowProp
                              )}
                            </Fragment>
                          )}

                        {_.isEmpty(this.state.data) &&
                          this.state.memberGridLoad === false &&
                          this.props.filterState.resultsAccordianType === 1 && (
                            <div class="container h-100">
                              <div class="row spinnerFacilityCategory">
                                <h5>No Members Found!</h5>
                              </div>
                            </div>
                          )}
                        {this.state.memberGridLoad === true && (
                          <div className="text-center pb-4 pt-2">
                            <div role="status" class="spinner-border">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </div>
                        )}
                        {/* for PDF AND CSV Downloading purpose only. Its Hidden by default. */}
                        {
                          <div id="pdfdiv" style={{ display: "none" }}>
                            <BootstrapTable
                              hover
                              remote
                              keyField="id"
                              data={this.state.downloadData}
                              columns={(() => {
                                let c = _.cloneDeep(this.columns);
                                if (
                                  c[0].dataField == "" &&
                                  this.props.facility
                                ) {
                                  c.splice(0, 1);
                                  console.log("columns2", this.columns);

                                  return c;
                                }
                                return this.columns;
                              })()}
                              false
                              false
                              false
                              id="membersListingPdf"
                            />
                          </div>
                        }
                        {/* for PDF AND CSV Downloading purpose only. Its Hidden by default. */}
                        {
                          <div
                            id="basicsearchpdfdiv"
                            style={{ display: "none" }}
                          >
                            <div className="collapse-user-box">
                              <h4>Active Employees </h4>{" "}
                              <p className="result">
                                ({this.state.activeTotalCount} results)
                              </p>
                            </div>
                            <div>
                              <BootstrapTable
                                hover
                                remote
                                keyField="id"
                                data={this.state.downloadDataActive}
                                columns={this.columns}
                                false
                                false
                                false
                                id="activemembersListingPdf"
                              />
                            </div>
                            <div className="collapse-user-box">
                              <h4>Inactive Employees </h4>{" "}
                              <p className="result">
                                ({this.state.inactiveTotalCount} results)
                              </p>
                            </div>
                            <div>
                              <BootstrapTable
                                hover
                                remote
                                keyField="id"
                                data={this.state.downloadDataInActive}
                                columns={this.columns}
                                false
                                false
                                false
                                id="inactivemembersListingPdf"
                              />
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </Tab>
                ))}
              </Tabs>
            ) : (
              <div className="row">
                <div className="col-12" style={{ minHeight: "fit-content" }}>
                  {totalCount == 0 && !this.props.facility && (
                    <div className="memberpage-tags pt-2">
                      <p>In the search field enter one of the following:</p>
                      <ul>
                        <li>First Name</li>
                        <li>Last Name</li>
                        <li>Member Number</li>
                      </ul>
                    </div>
                  )}

                  {totalCount > 0 &&
                    (!this.props.facilityDashboardState
                      .IsMemberAccordianLoading ||
                      this.props.filterState.isAdvancedFilterSelected ||
                      this.state.savedSearchId > 0) && (
                      <Fragment>
                        {/* //When advance filter and save search both selected */}
                        {this.RemotePagination(
                          data,
                          this.props.filterState.currentFilterResultPage,
                          sizePerPage,
                          this.handleTableChange,
                          totalCount,
                          showTotal,
                          this.rowEvents,
                          selectedFields,
                          selectRowProp
                        )}
                      </Fragment>
                    )}

                  {_.isEmpty(this.state.data) &&
                    this.state.memberGridLoad === false &&
                    this.props.filterState.resultsAccordianType === 1 && (
                      <div class="container h-100">
                        <div class="row spinnerFacilityCategory">
                          <h5>No Members Found!</h5>
                        </div>
                      </div>
                    )}
                  {this.state.memberGridLoad === true && (
                    <div className="text-center pb-4 pt-2">
                      <div role="status" class="spinner-border">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  )}
                  {/* for PDF AND CSV Downloading purpose only. Its Hidden by default. */}
                  {
                    <div id="pdfdiv" style={{ display: "none" }}>
                      <BootstrapTable
                        hover
                        remote
                        keyField="id"
                        data={this.state.downloadData}
                        columns={(() => {
                          let c = _.cloneDeep(this.columns);
                          if (c[0].dataField == "" && this.props.facility) {
                            c.splice(0, 1);
                            console.log("columns2", this.columns);

                            return c;
                          }
                          return this.columns;
                        })()}
                        false
                        false
                        false
                        id="membersListingPdf"
                      />
                    </div>
                  }
                  {/* for PDF AND CSV Downloading purpose only. Its Hidden by default. */}
                  {
                    <div id="basicsearchpdfdiv" style={{ display: "none" }}>
                      <div className="collapse-user-box">
                        <h4>Active Employees </h4>{" "}
                        <p className="result">
                          ({this.state.activeTotalCount} results)
                        </p>
                      </div>
                      <div>
                        <BootstrapTable
                          hover
                          remote
                          keyField="id"
                          data={this.state.downloadDataActive}
                          columns={this.columns}
                          false
                          false
                          false
                          id="activemembersListingPdf"
                        />
                      </div>
                      <div className="collapse-user-box">
                        <h4>Inactive Employees </h4>{" "}
                        <p className="result">
                          ({this.state.inactiveTotalCount} results)
                        </p>
                      </div>
                      <div>
                        <BootstrapTable
                          hover
                          remote
                          keyField="id"
                          data={this.state.downloadDataInActive}
                          columns={this.columns}
                          false
                          false
                          false
                          id="inactivemembersListingPdf"
                        />
                      </div>
                    </div>
                  }
                </div>
              </div>
            )
          ) : (
            <div className="row">
              <div
                className="col-12"
                style={{
                  minHeight: this.props.isEditFilterModal ? "275px" : "790px",
                }}
              >
                {totalCount == 0 &&
                  !this.props.facility &&
                  !this.props.SavedSearch.isOpenFilterModal && (
                    <div className="memberpage-tags pt-2">
                      <p>In the search field enter one of the following:</p>
                      <ul>
                        <li>First Name</li>
                        <li>Last Name</li>
                        <li>Member Number</li>
                      </ul>
                    </div>
                  )}

                {totalCount > 0 &&
                  (!this.props.facilityDashboardState
                    .IsMemberAccordianLoading ||
                    this.props.filterState.isAdvancedFilterSelected ||
                    this.state.savedSearchId > 0) && (
                    <Fragment>
                      {/* //When advance filter and save search both selected */}
                      {this.RemotePagination(
                        data,
                        this.props.filterState.currentFilterResultPage,
                        sizePerPage,
                        this.handleTableChange,
                        totalCount,
                        showTotal,
                        this.rowEvents,
                        selectedFields,
                        selectRowProp
                      )}
                    </Fragment>
                  )}

                {_.isEmpty(this.state.data) &&
                  this.state.memberGridLoad === false &&
                  this.props.filterState.resultsAccordianType === 1 && (
                    <div class="container h-100">
                      <div class="row spinnerFacilityCategory">
                        <h5>No Members Found!</h5>
                      </div>
                    </div>
                  )}
                {this.state.memberGridLoad === true && (
                  <div className="text-center pb-4 pt-2 memberGridLoader">
                    <div role="status" class="spinner-border">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                )}
                {/* for PDF AND CSV Downloading purpose only. Its Hidden by default. */}
                {
                  <div id="pdfdiv" style={{ display: "none" }}>
                    <BootstrapTable
                      hover
                      remote
                      keyField="id"
                      data={this.state.downloadData}
                      columns={(() => {
                        let c = _.cloneDeep(this.columns);
                        if (c[0].dataField == "" && this.props.facility) {
                          c.splice(0, 1);
                          //console.log("columns2", this.columns);

                          return c;
                        }
                        return this.columns;
                      })()}
                      false
                      false
                      false
                      id="membersListingPdf"
                    />
                  </div>
                }
                {/* for PDF AND CSV Downloading purpose only. Its Hidden by default. */}
                {
                  <div id="basicsearchpdfdiv" style={{ display: "none" }}>
                    <div className="collapse-user-box">
                      <h4>Active Employees </h4>{" "}
                      <p className="result">
                        ({this.state.activeTotalCount} results)
                      </p>
                    </div>
                    <div>
                      <BootstrapTable
                        hover
                        remote
                        keyField="id"
                        data={this.state.downloadDataActive}
                        columns={this.columns}
                        false
                        false
                        false
                        id="activemembersListingPdf"
                      />
                    </div>
                    <div className="collapse-user-box">
                      <h4>Inactive Employees </h4>{" "}
                      <p className="result">
                        ({this.state.inactiveTotalCount} results)
                      </p>
                    </div>
                    <div>
                      <BootstrapTable
                        hover
                        remote
                        keyField="id"
                        data={this.state.downloadDataInActive}
                        columns={this.columns}
                        false
                        false
                        false
                        id="inactivemembersListingPdf"
                      />
                    </div>
                  </div>
                }
              </div>
            </div>
          )}

          <AdvanceFilterModal
            {...this.props}
            onChangeTable={this.onAdvancedChangeTable.bind(this)}
            onResetTable={this.onResetTable}
            routeFromSaveSearchDDL={
              (this.props.location &&
                this.props.location.state &&
                this.props.location.state.routeFromDDLFiler) ||
              false
            }
          />

          {
            <CompositionModal
              showModal={this.state.showModal}
              onClose={this.hideModal}
              check={this.state.check}
              //addresses={this.state.emails}
              addresses={this.getRecipientFieldValue()}
              //refreshAddresses={this.getRecipientFieldValue}
              listType={
                this.props.FacilityMembers
                  ? MailGunEmailTypes.Facility
                  : this.props.SavedSearch.isSavedSearchSelected &&
                    this.props.melstate.savedSearchDllObject &&
                    this.props.melstate.savedSearchDllObject
                      .savedSearchPerformed
                    ? MailGunEmailTypes.Member
                    : 0
              }
              ///Save Search Id => Facility Id
              entityId={
                this.props.FacilityMembers
                  ? this.props.facilityId
                  : this.props.SavedSearch.isSavedSearchSelected &&
                    this.props.melstate.savedSearchDllObject &&
                    this.props.melstate.savedSearchDllObject
                      .savedSearchPerformed
                    ? this.props.melstate.savedSearchDllObject.SearchId
                    : 0
              }
              getAdditionalSubsribersEmailAddresses={
                this.props.getAdditionalSubsribersEmailAddresses
              }
              isSaveSearchFacilityCS={true}
              isBucketFilled={
                !_.isEmpty(JSON.parse(localStorage.getItem("BucketSearch")))
              }
              isFRTCS={false}
            />
          }

          {this.state.displayMembers && (
            <MemberBucket
              showModal={this.state.showBucketModal}
              onClose={this.hideBucketModal}
              displayMembers={this.state.displayMembers}
              SearchCriteria={this.props.filterState.filterData}
              handleDelete={this.removeSelected.bind(this)}
              FilterMembers={this.filterMembers.bind(this)}
              savedSearchId={this.state.savedSearchId}
              memberid={this.state.memberId}
              searchId={this.state.savedSearchId}
              Empty={this.EmptyBucket.bind(this)}
              setSavedSearchCriteria={this.setSavedSearchCriteria}
              forceSearchRefresh={this.handleTableChange}
              columns={this.state.customizeColumns}
              ViewBucket={this.viewAllbucket}
            />
          )}
        </div>
      </AUX>
    );
  }
}

// export default Users;
export default connect(
  (state) => ({
    filterState: state.advancedFilter,
    facilityDashboardState: state.facilityDashboard,
    formValues: state.form,
    currentState: state,
    SavedSearch: state.saveSearch,
    melstate: state.mailGunEmail,
  }),
  {
    ...actionFilter,
    ...actions,
    ...savedSearchAction,
    ...mailGunEmailActions,
  }
)(Users);
